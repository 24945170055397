import React, { Component } from "react";

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-start">
              © Xelya {new Date().getFullYear()}. {"Tous droits réservés"}.
            </div>
            <div>
                        <img className="img-responsive" src={process.env.PUBLIC_URL + '/img/logo_xelya_horizontal-blanc.png'} alt="Xelya" width="250px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
