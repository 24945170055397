import { useEffect, useState } from "react";
import React from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "reactstrap";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import { UrlUtils } from "../../../utils/UrlUtils";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import ContainerWrapper from "../../shared/ContainerWrapper";
import ContainerHeaderBar from "../../shared/ContainerHeaderBar";
import TicketImportFile from "./TicketImportFile";
import TicketConversation from "./TicketConversation";
import TicketFiles from "./TicketFiles";
import TicketComment from "./TicketUpdateComment";
import TicketRankDetail from "./TicketRankDetail";
import Error from "../../shared/Error";
import "./TicketDetail.scss";

const TicketDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setError({ message: `Invalid Id ${id}` });
      setLoading(false);
    } else {
      const request = API.getTicket(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  const renderForms = () => {
    return (
      <>
        <ContainerHeaderBar
          rightContent={
            <>
              <Link to={UrlUtils.tickets()}>
                <Button color="primary" className="mb-3">
                  <span>Liste Demandes</span>
                </Button>
              </Link>
            </>
          }
        />

        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-8">
            <ReadOnlyTableCellWrapper>
              <ReadOnlyTableCell fieldTitle="Code" fieldValue={entity.code} bold={true} />
              <ReadOnlyTableCell fieldTitle="Ouvert Par" fieldValue={entity.openedBy} />
              <ReadOnlyTableCell fieldTitle="Sujet" fieldValue={entity.subject} />
              <ReadOnlyTableCell fieldTitle="Categorie" fieldValue={entity.category} />
              <ReadOnlyTableCell fieldTitle="Type" fieldValue={entity.type} />
              <ReadOnlyTableCell fieldTitle="Sevérité" fieldValue={entity.severity} />
              <ReadOnlyTableCell fieldTitle="Ouverture" fieldValue={entity.openTime} fieldType="datetime" />
              <ReadOnlyTableCell fieldTitle="Fermeture" fieldValue={entity.closeTime} fieldType="datetime" />
              <ReadOnlyTableCell fieldTitle="Statut" fieldValue={entity.status} />
              <tr>
                <td>Pièces Jointes</td>
                <td className="text-nowrap">
                  <TicketFiles id={entity.id} attachements={entity.attachements} />
                </td>
              </tr>
            </ReadOnlyTableCellWrapper>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {entity.statusValue !== "Closed" && (
              <div className="mb-3">
                <TicketImportFile id={id} />
              </div>
            )}
            {(entity.statusValue === "Closed" || entity.satisfactionNote !== null) && <TicketRankDetail id={entity.id} note={entity.satisfactionNote} comment={entity.satisfactionComment} />}
          </div>
        </div>
        <hr />
        {entity.statusValue !== "Closed" && entity.statusValue !== "Merged" && (
          <div className="mb-3">
            <TicketComment id={entity.id} updateMethod={API.updateTicketAddComment} commentRequired={true} color="primary" buttonTitle="Ajouter" placeholder="Ajouter un commentaire additionnel" />
          </div>
        )}
        <div className="mb-3">
          <TicketConversation id={entity.id} conversations={entity.conversations} />
        </div>
        {entity.statusValue !== "Closed" && entity.statusValue !== "Merged" && (
          <div>
            <TicketComment id={entity.id} updateMethod={API.closetTicket} commentRequired={true} color="danger" buttonTitle="Fermer la demande" placeholder="Commentaire de fermeture (obligatoire)" />
          </div>
        )}
        {entity.statusValue === "Closed" && (
          <div>
            <TicketComment
              id={id}
              updateMethod={API.reopentTicket}
              commentRequired={false}
              color="warning"
              buttonTitle="Ré-ouvrir cette demande"
              placeholder="Commentaire de ré-ouverture (optionnel)"
            />
          </div>
        )}
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return renderForms();
    }
  };

  return (
    <>
      <HeaderMenu title="Détail d'une demande"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

export default TicketDetails;
