import { Input, InputGroup, InputGroupText } from "reactstrap";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const MonthInput = ({ value, onClick, onChange }) => {
  return (
    <>
      <InputGroup>
        <InputGroupText style={{ width: "80px" }}>Période</InputGroupText>
        <Input value={value} onClick={onClick} onChange={onChange} />
      </InputGroup>
    </>
  );
};

const MonthInputPicker = ({ className, date, onDateChanged }) => {
  return (
    <>
      <DatePicker
        className={className}
        selected={date}
        todayButton="Aujourd'hui"
        locale="fr"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
        onChange={(date) => onDateChanged(date)}
        customInput={<MonthInput />}
      />
    </>
  );
};

export default MonthInputPicker;
