import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Layout } from "../layout/Layout";

import Error from "../shared/Error";
import { useUser } from "../../utils/UserContext";
import { Authenticate } from "./Authenticate";

import "./extranet-legacy.scss";
import "./common.scss";

import BillDetails from "../page/bills/BillDetails";
import BillList from "../page/bills/BillList";
import LegalNotices from "../page/LegalNotices";
import News from "../page/News";
import ServiceList from "../page/ServiceList";
import TicketList from "../page/tickets/TicketList";
import NewUserQuote from "../page/NewUserQuote";
import NewTicket from "../page/tickets/NewTicket";
import TicketDetails from "../page/tickets/TicketDetail";
import EmailDetails from "../page/tickets/EmailDetails";
import TicketRank from "../page/tickets/TicketRank";
import DeviceList from "../page/devices/DeviceList";
import Impersonate from "../page/Impersonate";
import UserAccountDetails from "../page/useraccounts/UserAccountDetailsO365";
import SDAList from "../page/sdas/SDAList";
import QuoteList from "../page/quotes/QuoteList";
import QuoteDetails from "../page/quotes/QuoteDetails";
import MyUserAccountDetail from "../page/useraccounts/MyUserAccountDetail";
import PhoneRecordList from "../page/phonerecords/PhoneRecordList";

//import { AuthenticateO365 } from "./AuthenticateO365";
//import UserAccountList from "../page/useraccounts/UserAccountList";
//import Contacts from "../page/contacts/Contacts";


const AuthorizeRoute = ({ isAuthorized, children, ...rest }) => {
  const user = useUser();
  if (isAuthorized(user)) return <Route {...rest}>{children}</Route>;
  else return <Error error={{ status: 403 }} />;
};

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Authenticate>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/news" />
            </Route>
            <Route exact path="/news" component={News} />
            <Route exact path="/legalnotices" component={LegalNotices} />
            {/* <Route exact path="/contacts" component={Contacts} /> */}

            <Route exact path="/tickets" component={TicketList} />
            <Route exact path="/tickets/new" component={NewTicket} />
            <Route exact path="/tickets/:id" component={TicketDetails} />
            <Route exact path="/tickets/:id/rank" component={TicketRank} />
            <Route exact path="/tickets/:id/email/:emailId" component={EmailDetails} />
            <Route exact path="/me" component={MyUserAccountDetail} />
            <Route exact path="/useraccounts/:id" component={UserAccountDetails} />

            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/services" component={ServiceList} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/newuser" component={NewUserQuote} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/bills" component={BillList} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/bills/:id" component={BillDetails} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/quotes" component={QuoteList} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/quotes/:id" component={QuoteDetails} />

            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/sdas" component={SDAList} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/devices" component={DeviceList} />
            <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole || u.hasBillingRole} exact path="/phonerecords" component={PhoneRecordList} />

            <AuthorizeRoute isAuthorized={(u) => u.hasColibriRole || u.isImpersonating} exact path="/impersonate" component={Impersonate} />

            {/* <AuthenticateO365>
              <AuthorizeRoute isAuthorized={(u) => u.hasAdminRole || u.hasEntityAdminRole} exact path="/useraccounts" component={UserAccountList} />
            </AuthenticateO365> */}
            <Route>
              <Error error={{ status: 404 }} />
            </Route>
          </Switch>
        </Layout>
      </Authenticate>
    );
  }
}
