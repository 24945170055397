import { useState, useEffect } from "react";
import RadioFilter from "../RadioFilter";

export const ViewFilter = ({ views, defaultView, setSelectedValue }) => {
  const [activeView, setActiveView] = useState(defaultView);

  useEffect(() => {
    setSelectedValue(activeView);
  }, [activeView]);

  const onViewFilterChanged = (value) => {
    setActiveView(value);
  };

  return <>{views && views.length > 1 && <RadioFilter name={"view-filter"} values={views} activeValue={activeView} viewChangedCallback={onViewFilterChanged} />}</>;
};

export default ViewFilter;
