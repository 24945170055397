import { Input, Label, FormFeedback } from "reactstrap";
import { useField } from "formik";

const FileInput = ({ label, name }) => {
  const [field, meta, helper] = useField(name);

  const onFileChanged = (file) => {
    helper.setValue(file);
    helper.setTouched();
  };

  return (
    <>
      <Label className="fw-bold" htmlFor={name}>
        {label}
      </Label>
      <Input
        type="file"
        invalid={meta.touched && meta.error ? true : false}
        label={label}
        name={name}
        onChange={(e) => {
          onFileChanged(e.currentTarget.files[0]);
        }}
      />
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </>
  );
};

export default FileInput;
