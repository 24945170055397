import { useState, useEffect } from "react";
import { API } from "../../../utils/API";
import { Autocomplete } from "../AutoComplete";

export const TypeAheadScopeFilter = ({ setSelectedValue, getValues, placeHolder, pageSize, containsSearch }) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (value) setSelectedValue(value.id);
        else setSelectedValue(0);
    }, [value]);

    const OnValueChanged = (value) => {
        setValue(value);
    };

    var opts = {};
    if (pageSize != null && pageSize > 0) {
        opts['pageSize'] = pageSize;
    }

    if (containsSearch != null && containsSearch === true) {
        opts['containsSearch'] = true;
    }

    return (
        <>
            <Autocomplete className="w-100" getMatches={getValues} value={value} onChange={(v) => OnValueChanged(v)} inputPlaceholder={placeHolder} itemToString={(option) => option?.name || ""} {...opts} />
        </>
    );
};

export default TypeAheadScopeFilter;
