import React from "react";
import { Container } from "reactstrap";

export const ContainerWrapper = (props) => {
  return (
    <Container id="contents" className="my-5">
      {props.children}
    </Container>
  );
};

export default ContainerWrapper;
