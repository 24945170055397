import { Link } from "react-router-dom";
import React from "react";
import { DecimalUtils } from "../../../utils/DecimalUtils";
import BillRelatedItem from "./BillRelatedItem";
import { DateUtils } from "../../../utils/DateUtils";

const BillDeposits = ({ deposits }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.date)}</span>,
        Footer: <span className="fw-bold">Total</span>,
      },
      {
        Header: "Référence",
        accessor: "code",
        Cell: ({ row }) => <Link to={`${row.original.id}`}>{row.original.code}</Link>,
      },
      {
        Header: "Montant HT",
        accessor: "netAmount",
        className: "text-end",
        headerClassName: "text-end",
        footerClassName: "text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.netAmount)}</span>,
        Footer: (info) => {
          const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.netAmount + sum, 0), [info.rows]);

          return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
        },
      },
      {
        Header: "TVA",
        accessor: "taxes",
        className: "text-end",
        headerClassName: "text-end",
        footerClassName: "text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.taxes)}</span>,
        Footer: (info) => {
          const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.taxes + sum, 0), [info.rows]);

          return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
        },
      },
      {
        Header: "Montant TTC",
        accessor: "amount",
        className: "text-end",
        headerClassName: "text-end",
        footerClassName: "text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.grossAmount)}</span>,
        Footer: (info) => {
          const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.grossAmount + sum, 0), [info.rows]);

          return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
        },
      },
    ],
    []
  );

  return (
    <div className="pt-2">
      <BillRelatedItem columns={columns} data={deposits} title="Acomptes" />
    </div>
  );
};

export default BillDeposits;
