import React, { useState, useRef, useEffect } from "react";
import { useCombobox } from "downshift";
import { FormFeedback, Spinner } from "reactstrap";

// https://stackblitz.com/edit/react-downshift-select-hmkjfn?file=index.js

export const Autocomplete = ({
  getMatches,
  value,
  onChange,
  onBlur,
  name,
  error,
  itemToString,
  renderMenuItem,
  inputPlaceholder,
  emptyMessage,
  searchingMessage,
  debounce,
  disabled,
  pageSize,
  containsSearch,
  className,
}) => {
  const [inputItems, setInputItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTimeoutId, setSearchTimeoutId] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    if (onBlur) {
      const input = inputRef.current;
      input.addEventListener("blur", (e) => setTimeout(() => onBlur(e), 0));
      return () => input.removeEventListener("blur", onBlur);
    }
  }, [onBlur]);

  const stopSearching = () => {
    setIsLoading(false);
    clearTimeout(searchTimeoutId);
  };

  const search = (query) => {
    stopSearching();

    setIsLoading(true);
    let timeoutId = setTimeout(async () => {
      const request = getMatches(0, pageSize, query, containsSearch);
      request
        .then((data) => {
          if (!request.aborted) {
            setInputItems(data.results);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (!request.aborted) {
            console.error(err);
            setIsLoading(false);
          }
        });

      return request.abort;
    }, debounce);

    setSearchTimeoutId(timeoutId);
  };

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, closeMenu, selectItem, setInputValue, inputValue, selectedItem } = useCombobox({
    items: inputItems,
    // having a empty string fallback prevent this
    //https://github.com/downshift-js/downshift#control-props
    // Warning: A component is changing a controlled input of type text to be uncontrolled. Input elements should not switch from controlled to uncontrolled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component
    // cf https://github.com/downshift-js/downshift/issues/478#issuecomment-455668762
    selectedItem: value ?? "",
    itemToString: (item) => itemToString(item),
    onSelectedItemChange: (change) => {
      onChange(change.selectedItem);
      inputRef.current.blur();
    },
    onStateChange: (state) => {
      switch (state.type) {
        case useCombobox.stateChangeTypes.InputChange:
          search(state.inputValue);
          break;

        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          search(inputValue);
          break;

        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          stopSearching();
          setInputItems([]);
          let selectedItemValue = itemToString(selectedItem);
          if (inputValue !== selectedItemValue) {
            setInputValue(selectedItemValue);
          }
          break;
        default:
          break;
      }
    },
  });

  const createHighlightItemStyle = (index) => {
    return {
      backgroundColor: highlightedIndex === index ? "#bde4ff" : "inherit",
    };
  };

  const remove = (e) => {
    closeMenu();
    // NOTE : selectItem doit être appelé après closeMenu pour une raison qui m'échappe à ce stade,
    // Si selectItem est appelé après, on ne rentre pas dans onSelectedItemChange
    selectItem(null);
    stopSearching();
  };

  return (
    <div className={"autocomplete position-relative " + (className || "")}>
      <div {...getComboboxProps({ className: "input-group" })}>
        <input
          {...getInputProps({
            ref: inputRef,
            className: `form-control ${error ? "is-invalid" : ""}`,
            onFocus: () => !isOpen && openMenu(),
            placeholder: inputPlaceholder || "Rechercher",
            disabled,
            name,
          })}
        />
        <button type="button" className="btn btn-outline-secondary" tabIndex={-1} onClick={remove} title="Supprimer la sélection" aria-label="Supprimer la sélection" disabled={disabled}>
          <i className="bi-x-lg"></i>
        </button>
        {error && <FormFeedback>{error}</FormFeedback>}
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul
            className="dropdown-menu d-block w-100 mt-1"
            style={{
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {!isLoading &&
              inputItems.map((item, index) => (
                <li
                  {...getItemProps({
                    item,
                    index,
                    style: createHighlightItemStyle(index),
                    key: `${item.id}${index}`,
                    className: "cursor-pointer",
                  })}
                >
                  <div className="dropdown-item">{renderMenuItem ? renderMenuItem(item) : itemToString(item)}</div>
                </li>
              ))}
            {!isLoading && inputItems.length === 0 && (
              <li className="text-muted">
                <div className="dropdown-item disabled">{emptyMessage}</div>
              </li>
            )}
            {isLoading && (
              <li className="text-muted">
                <div className="dropdown-item disabled">
                  <Spinner size="sm" className="me-2" />
                  {searchingMessage}
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

Autocomplete.defaultProps = {
  inputPlaceholder: "Rechercher ...",
  emptyMessage: "Aucun résultat",
  searchingMessage: "Recherche en cours ...",
  debounce: 300,
  disabled: false,
  pageSize: 20,
  containsSearch: false
};
