import React, { useState } from "react";
import { API } from "../../../utils/API";
import Error from "../../shared/Error";
import { Button } from "reactstrap";

const TicketFiles = ({ id, attachements }) => {
  const [error, setError] = useState();

  const deleteFile = (fileId) => {
    const request = API.deleteTicketFile(id, fileId);
    request
      .then((data) => {
        refreshCurrentPage();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const downloadFile = (fileId) => {
    API.donwloadTicketFile(id, fileId);
  };

  const refreshCurrentPage = () => {
    window.location.reload(false);
  };

  return (
    <div>
      {error && <Error error={error} />}
      {attachements.map((value, index) => (
        <div key={index} className="my-1">
          <span className="customlink fw-bold" onClick={() => downloadFile(value.id)}>
            {value.name}
          </span>
          <Button className="ms-1 btn-sm" color="danger" onClick={() => deleteFile(value.id)}>
            Supprimer
          </Button>
        </div>
      ))}
    </div>
  );
};

export default TicketFiles;
