import { FormGroup, Input, Label } from "reactstrap";

const RadioFilter = ({ name, values, activeValue, viewChangedCallback }) => {
  const onViewChanged = (viewValue) => {
    viewChangedCallback(viewValue);
  };

  return (
    <FormGroup tag="fieldset">
      {values.map((value, index) => (
        <FormGroup check className="form-check-inline" key={index}>
          <Input id={`${name}-${value.id}`} name={name} type="radio" value={value.id} checked={activeValue === value.id} onChange={(e) => onViewChanged(e.target.value)} />{" "}
          <Label className="fw-bold" check for={`${name}-${value.id}`}>
            {value.name}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  );
};

export default RadioFilter;
