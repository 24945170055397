import React from "react";

export const HeaderMenu = ({ title }) => {
  return (
    <div className="container-blue header-title py-3">
      <div className="container">
        <h2>
          <span id="tabelLabel">{title}</span>
        </h2>
      </div>
    </div>
  );
};

export default HeaderMenu;
