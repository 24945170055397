import { useEffect, useState } from "react";

import { API } from "../../../utils/API";
import { Loading } from "../../shared/Loading";
import Error from "../../shared/Error";
import DataTableClientSide from "../../shared/DataTableClientSide";

const UserAccountServices = ({ userId }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState();

  const columns = [
    {
      id: "Name",
      Header: "Libellé",
      accessor: "name",
    },
  ];

  useEffect(() => {
    const request = API.getUserAccountServices(userId);
    request
      .then((data) => {
        if (!request.aborted) {
          setServices(data);
          setError(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setServices(null);
          setError(error);
          setLoading(false);
        }
      });

    return request.abort;
  }, []);

  const renderContent = () => {
    if (loading) {
      return <Loading></Loading>;
    } else {
      return (
        <>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Sercices Ozmo</h5>
              <DataTableClientSide columns={columns} data={services} />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {error && <Error error={error} />}
      {!error && renderContent()}
    </>
  );
};

export default UserAccountServices;
