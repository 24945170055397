import React, { useEffect, useState } from "react";
import { HeaderMenu } from "../navigation/HeaderMenu";
import { Loading } from "../shared/Loading";
import { Form, Row, Col } from "reactstrap";
import { API } from "../../utils/API";
import { DateUtils } from "../../utils/DateUtils";
import "./ServiceList.scss";
import { UrlUtils } from "../../utils/UrlUtils";
import ContainerWrapper from "../shared/ContainerWrapper";
import { ExpandableTable, getExpandableColumnHeader } from "../shared/ExpandableTable";
import DecimalUtils from "../../utils/DecimalUtils";
import Error from "../shared/Error";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import ContainerHeaderBar from "../shared/ContainerHeaderBar";
import BillingEntityScopeFilter from "../shared/ScopeFilter/BillingEntityScopeFilter";
import TypeAheadScopeFilter from "../shared/ScopeFilter/TypeAheadScopeFilter";

const ServiceList = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState(null);

  const [selectedServiceUser, setSelectedServiceUser] = useState(0);
  const [selectedBillingEntity, setSelectedBillingEntity] = useState(0);

  const [error, setError] = useState();

  const columns = [
    getExpandableColumnHeader(),
    {
      Header: "Libellé",
      Footer: <span className="fw-bold">Total</span>,
      accessor: "name",
    },
    {
      Header: "Entité de facturation",
      accessor: "entityName",
    },
    {
      Header: "Service",
      accessor: "service",
    },
    {
      Header: "Activation",
      accessor: "start",
      Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.start)}</span>,
    },
    {
      Header: "Qté",
      accessor: "qty",
      className: "text-nowrap text-end",
      Cell: ({ row }) => <span>{DecimalUtils.displayNumber(row.original.qty, 2, true)}</span>,
    },
    {
      Header: "Prix unit.",
      accessor: "unitprice",
      className: "text-nowrap text-end",
      Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.unitprice)}</span>,
    },
    {
      Header: "(FMS)",
      accessor: "unitSetupPrice",
      className: "text-nowrap text-end",
      Cell: ({ row }) => {
        if (typeof row.original.unitSetupPrice !== "undefined") return <span>{`(${DecimalUtils.displayNumber(row.original.unitSetupPrice, 2, true)})`}</span>;
        else return "";
      },
    },
    {
      Header: "Montant HT",
      accessor: "totalHT",
      className: "text-nowrap text-end",
      Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.totalHT)}</span>,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(() => {
          var lowDepthRows = info.rows.filter(function (row) {
            return row.depth === 0;
          });
          return lowDepthRows.reduce((sum, row) => row.values.totalHT + sum, 0);
        }, [info.rows]);

        return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
      },
    },
    {
      Header: "TVA (20,00 %)",
      accessor: "tva",
      className: "text-nowrap text-end",
      Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.tva)}</span>,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(() => {
          var lowDepthRows = info.rows.filter(function (row) {
            return row.depth === 0;
          });
          return lowDepthRows.reduce((sum, row) => row.values.tva + sum, 0);
        }, [info.rows]);

        return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
      },
    },
    {
      Header: "Montant TTC",
      accessor: "totalTTC",
      className: "text-nowrap text-end",
      Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.totalTTC)}</span>,
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(() => {
          var lowDepthRows = info.rows.filter(function (row) {
            return row.depth === 0;
          });
          return lowDepthRows.reduce((sum, row) => row.values.totalTTC + sum, 0);
        }, [info.rows]);

        return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
      },
    },
  ];

  useEffect(() => {
    const request = API.getServiceDetails(selectedBillingEntity, selectedServiceUser);
    request
      .then((data) => {
        if (!request.aborted) {
          setServices(data);
          setError(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setServices(null);
          setError(error);
          setLoading(false);
        }
      });
  }, [selectedBillingEntity, selectedServiceUser]);

  const onBillingEntityFilterChange = (selectedValue) => {
    setSelectedBillingEntity(selectedValue);
  };

  const onServiceUserFilterChange = (selectedValue) => {
    setSelectedServiceUser(selectedValue);
  };
  const renderContent = () => {
    if (loading) {
      return <Loading></Loading>;
    } else {
      return (
        <>
          <ContainerHeaderBar
            leftContentClassName={"col-md-8 form-inline"}
            leftContent={
              <Form>
                <Row className="align-items-center">
                  <Col>
                    <BillingEntityScopeFilter setSelectedValue={onBillingEntityFilterChange} />
                  </Col>
                  <Col>
                    <TypeAheadScopeFilter getValues={API.getServiceDetailsUsers} placeHolder={"Filtrer par Utilisateur ..."} setSelectedValue={onServiceUserFilterChange} containsSearch={true} />
                  </Col>
                </Row>
              </Form>
            }
            rightContent={
              <Link to={UrlUtils.newUser()}>
                <Button color="primary">
                  <i className="bi bi-plus me-1"></i>
                  <span>Nouvel utilisateur</span>
                </Button>
              </Link>
            }
          />
          {services && columns && <ExpandableTable columns={columns} data={services} hasFooter={true} />}
        </>
      );
    }
  };

  return (
    <div>
      <HeaderMenu title="Abonnements"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContent()}
      </ContainerWrapper>
    </div>
  );
};

export default ServiceList;
