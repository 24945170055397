import React, { Fragment, useState } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useEffect } from "react";
import ColumnFilterWrapper from "./Filters/ColumnFilterWrapper";
// import PropTypes from "prop-types";

const DataTable = ({ columns, data, pageSize: defaultPageSize, pageCount: serverPageCount, footer, updatePage, updateFilters, updateSortBy, resetPage, resetAll }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setSortBy,
    setAllFilters,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns: columns,
      data,
      autoResetPage: false,
      autoResetSortBy: false,
      // autoResetFilters: false,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount: serverPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [activeFilterId, setActiveFilterId] = useState();

  useEffect(() => {
    // console.log(
    //   `Effect PAGE => resetAll: ${resetAll}, resetPage: ${resetPage}, pageIndex:  ${pageIndex}, pageSize: ${pageSize}, sortby: ${JSON.stringify(sortBy)}, filters: ${JSON.stringify(filters)}`
    // );
    updatePage(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    // console.log(`Effect SORTBY => resetAll: ${resetAll}, pageIndex:  ${pageIndex}, pageSize: ${pageSize}, sortby: ${JSON.stringify(sortBy)}`);
    updateSortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    // console.log(`Effect FILTERS => resetAll: ${resetAll}, pageIndex:  ${pageIndex}, pageSize: ${pageSize}, filters: ${JSON.stringify(filters)}`);
    updateFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (resetPage) {
      // console.log(`Effect RESET PAGE =>  resetPage: ${resetPage}}`);
      gotoPage(0);
    }
  }, [resetPage]);

  useEffect(() => {
    if (resetAll) {
      // console.log(`Effect RESET ALL =>  resetAll: ${resetAll}`);
      setSortBy([]);
      setAllFilters([]);
      setActiveFilterId(null);
      gotoPage(0);
    }
  }, [resetAll]);

  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <Fragment key={index}>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                    },
                  ])}
                >
                  {column.canFilter && <ColumnFilterWrapper column={column} activeFilter={activeFilterId} filterChangeCb={setActiveFilterId} />}

                  <span {...column.getHeaderProps([column.getSortByToggleProps()])}>
                    {column.canSort && (
                      <span className="customlink">
                        {column.render("Header")} {column.isSorted ? <i className={`bi ${column.isSortedDesc ? "bi-caret-down" : "bi-caret-up"}`}></i> : ""}
                      </span>
                    )}
                    {!column.canSort && <span>{column.render("Header")}</span>}
                  </span>
                </th>
              ))}
            </tr>
          </Fragment>
        ))}
      </thead>
    );
  };

  const renderRows = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {page && page.length > 0 && (
          <>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                          },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </>
        )}
        {(!page || page.length === 0) && (
          <tr>
            <td colSpan={columns.length}>Aucune donnée à afficher</td>
          </tr>
        )}
      </tbody>
    );
  };

  const renderFooter = () => {
    return footer();
  };

  const renderPagination = () => {
    return (
      <>
        {pageCount > 1 && (
          <Pagination>
            <PaginationItem disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
              <PaginationLink first />
            </PaginationItem>

            <PaginationItem disabled={!canPreviousPage} onClick={() => previousPage()}>
              <PaginationLink previous />
            </PaginationItem>

            <PaginationItem active>
              <PaginationLink>
                {pageIndex + 1} / ({pageCount})
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={!canNextPage} onClick={() => nextPage(0)}>
              <PaginationLink next />
            </PaginationItem>

            <PaginationItem disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}>
              <PaginationLink last />
            </PaginationItem>
          </Pagination>
        )}
      </>
    );
  };

  return (
    <>
      <div className="table-responsive">
        <Table striped hover {...getTableProps()}>
          {renderHeader()}
          {renderRows()}
          {footer && renderFooter()}
        </Table>
        {renderPagination()}
      </div>
    </>
  );
};

// DataTable.propTypes = {
//   name: PropTypes.string.isRequired,
//   touchedOnly: PropTypes.bool
// };

// DataTable.defaultProps = {
//   touchedOnly: false
// };

export default DataTable;
