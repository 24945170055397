import { useState } from "react";
import React from "react";
import { Table } from "reactstrap";
import { DecimalUtils } from "../../../utils/DecimalUtils";
import ExpandableRow from "../../shared/ExpandableRow";

const BillServiceDetail = ({ serviceDetail }) => {
  return (
    <>
      <Table striped key={"ServiceDetail-" + serviceDetail.id}>
        <thead>
          <tr>
            <th className="typeGroupHeader" colSpan={2}>
              {serviceDetail.name}
            </th>
            <th className="typegroup" width="200px">
              {serviceDetail.isProratable ? "Période" : ""}
            </th>
            <th className="typegroup">Qté.</th>
            <th className="typegroup">{serviceDetail.isProratable ? "Prorata" : ""}</th>
            <th className="typegroup text-end">Prix unit.</th>
            <th className="typegroup text-end">Montant HT</th>
          </tr>
        </thead>
        <tbody>
          {serviceDetail.groups.map((categoryDetail, index) => (
            <React.Fragment key={serviceDetail.id + "-" + index}>
              <tr className="catGroup">
                <td className="catGroupHeader" colSpan={2}>
                  {categoryDetail.name}
                </td>
                <td className="catGroup" colSpan={7}></td>
              </tr>
              {categoryDetail.items.map((categoryItem, itemIndex) => (
                <React.Fragment key={serviceDetail.id + "-" + index + "-" + itemIndex}>
                  <BillCategoryItems serviceDetail={serviceDetail} categoryItem={categoryItem} id={serviceDetail.id + "-" + index + "-" + itemIndex} />
                </React.Fragment>
              ))}
              <tr>
                <td colSpan={5}></td>
                <td className="subTotalHeader text-end">Sous-Total</td>
                <td className="subTotal text-end fw-bold">{DecimalUtils.displayPrice(categoryDetail.totalHT, 2)}</td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td colSpan={5}></td>
            <td className="totalHeader text-end">Total</td>
            <td className="total text-end fw-bold">{DecimalUtils.displayPrice(serviceDetail.totalHT, 2)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const BillCategoryItems = ({ serviceDetail, categoryItem }) => {
  const [collapsed, setCollapsed] = useState(true);

  const renderExpandCell = () => {
    return (
      <>
        <td className="itemLabel">{categoryItem.name}</td>
        <td>{serviceDetail.isProratable ? categoryItem.period : ""}</td>
        <td>{serviceDetail.isProratable ? DecimalUtils.displayNumber(categoryItem.qty, 2, true) : DecimalUtils.displayNumber(categoryItem.prorata, 2, true)}</td>
        <td>{serviceDetail.isProratable ? DecimalUtils.displayNumber(categoryItem.prorata, 2, true) : ""}</td>
        <td className="text-end">{DecimalUtils.displayPrice(categoryItem.unitPrice, 2)}</td>
        <td className="text-end">{DecimalUtils.displayPrice(categoryItem.totalHT, 2)}</td>
      </>
    );
  };

  const renderExpandedRows = () => {
    return categoryItem.details.map((itemDetail, index) => (
      <tr key={index} className="detail">
        <td></td>
        <td className="detailLabel">{itemDetail.name}</td>
        <td>{itemDetail.period}</td>
        <td>{DecimalUtils.displayNumber(itemDetail.qty, 2, true)}</td>
        <td>{DecimalUtils.displayNumber(itemDetail.prorata, 2, true)}</td>
        <td colSpan={2}></td>
      </tr>
    ));
  };

  return (
    <ExpandableRow
      canCollapse={categoryItem.details && categoryItem.details.length > 0}
      collapsed={collapsed}
      toggle={() => setCollapsed(!collapsed)}
      columns={() => renderExpandCell()}
      details={() => renderExpandedRows()}
    />
  );
};

export default BillServiceDetail;
