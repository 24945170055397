import { useEffect, useState } from "react";
import React from "react";
import { useParams, Link } from "react-router-dom";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import { UrlUtils } from "../../../utils/UrlUtils";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import { Table, Button } from "reactstrap";
import ContainerWrapper from "../../shared/ContainerWrapper";
import ContainerHeaderBar from "../../shared/ContainerHeaderBar";
import BillServiceDetail from "./BillServiceDetail";
import BillSettlements from "./BillSettlements";
import BillCredits from "./BillCredits";
import BillDeposits from "./BillDeposits";
import BillVentilations from "./BillVentilations";
import DecimalUtils from "../../../utils/DecimalUtils";
import Error from "../../shared/Error";

const BillDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setError({ message: `Invalid Id ${id}` });
      setLoading(false);
    } else {
      const request = API.getBill(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  const onPrintClick = async () => {
    const parsedId = parseInt(id, 10);
    var request = API.printBill(parsedId);
    request.catch((error) => {
      if (!request.aborted) setError(error);
    });
  };

  const renderForms = () => {
    return (
      <>
        <ContainerHeaderBar
          rightContent={
            <>
              {entity.hasPdf && (
                <Button color="success" className="mb-3" onClick={() => onPrintClick()}>
                  <i className="bi bi-printer me-1"></i>
                  <span>Imprimer</span>
                </Button>
              )}
              <Link to={UrlUtils.bills()}>
                <Button color="primary" className="ms-1 mb-3">
                  <span>Liste Factures</span>
                </Button>
              </Link>
            </>
          }
        />

        <ReadOnlyTableCellWrapper>
          <ReadOnlyTableCell fieldTitle="Référence / Chrono" fieldValue={`${entity.code} / ${entity.chrono}`} fieldType="string" bold={true} />
          <ReadOnlyTableCell fieldTitle="Echéance" fieldValue={entity.duedate} fieldType="date" bold={true} />
          <ReadOnlyTableCell fieldTitle="Type" fieldValue={entity.typeName} />
          <ReadOnlyTableCell fieldTitle="Statut" fieldValue={entity.status} />
          <ReadOnlyTableCell fieldTitle="Date" fieldValue={entity.date} fieldType="date" />
          <ReadOnlyTableCell fieldTitle="Période" fieldValue={entity.period} />
          <ReadOnlyTableCell fieldTitle="Ajustement" fieldValue="-" />
        </ReadOnlyTableCellWrapper>

        <br />

        <Table striped>
          <thead>
            <tr>
              <td className="fw-bold">Net à payer</td>
              <td className="text-end fw-bold">Montant HT</td>
              <td className="text-end fw-bold">{`TVA ${entity.taxRate} %`}</td>
              <td className="text-end fw-bold">Montant TTC</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.netAmount)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.taxes)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.grossAmount)}</td>
            </tr>
          </tbody>
        </Table>

        <br />

        <Table striped>
          <thead>
            <tr>
              <td className="fw-bold">Restant à régler / Restant à ventiler</td>
              <td className="text-end"></td>
              <td className="text-end"></td>
              <td className="text-end fw-bold">Montant TTC</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="text-end"></td>
              <td className="text-end"></td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.balance)}</td>
            </tr>
          </tbody>
        </Table>

        <br />

        {entity.details.map((serviceDetail) => (
          <BillServiceDetail key={serviceDetail.id} serviceDetail={serviceDetail} id={serviceDetail.Id} />
        ))}

        {entity.displaySettlements && <BillSettlements settlements={entity.settlements} />}
        {entity.displayCredits && <BillCredits credits={entity.credits} />}
        {entity.displayDeposits && <BillDeposits deposits={entity.deposits} />}
        {entity.displayVentilations && <BillVentilations ventilations={entity.ventilations} />}
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return <div className="table-responsive">{renderForms()}</div>;
    }
  };

  return (
    <>
      <HeaderMenu title="Détail de facture"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

export default BillDetails;
