import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { HeaderMenu } from "../../navigation/HeaderMenu";

import { API, FilterFieldTypes } from "../../../utils/API";
import { DateUtils } from "../../../utils/DateUtils";
import { UrlUtils } from "../../../utils/UrlUtils";
import { useUser } from "../../../utils/UserContext";

import ContainerWrapper from "../../shared/ContainerWrapper";
import CheckBoxColumnFilter from "../../shared/Filters/CheckBoxColumnFilter";
import { DateRangeColumnFilter } from "../../shared/Filters/DateRangeColumnFilter";
import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import ViewFilter from "../../shared/ScopeFilter/ViewFilter";
import TypeAheadColumnFilter from "../../shared/Filters/TypeAheadColumnFilter";
import EntityList from "../../shared/EntityList";

const TicketList = () => {
  const user = useUser();

  const statusFilterValues = [
    { id: 0, name: "Nouvelle" },
    { id: 5, name: "Ouverte" },
    { id: 30, name: "En attente" },
    { id: 40, name: "Fermée" },
    { id: -10, name: "Fusionnée" },
  ];

  const initColumns = (viewValue) => {
    let columnsArray = [];

    columnsArray.push({
      id: "Code",
      Header: "Ref",
      accessor: "code",
      headerClassName: "text-nowrap",
      className: "text-nowrap",
      Cell: ({ row }) => <Link to={UrlUtils.ticketDetail(row.original.id)}>{row.original.code}</Link>,
      disableFilters: true,
    });

    if (user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) {
      columnsArray.push({
        id: "BillingEntityName",
        Header: "Entité de facturation",
        accessor: "billingEntityName",
        disableFilters: true,
      });
    }

    if (viewValue === "all" && (user.hasAdminRole || user.hasEntityAdminRole)) {
      columnsArray.push({
        id: "OpenedBy",
        Header: "Ouvert Par",
        accessor: "openedBy",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.Entity,
        getFilterValues: (pageIndex, pageSize, search) => API.getTicketUserAccounts(pageIndex, pageSize, search),
        Filter: (column, togglefilter) => <TypeAheadColumnFilter placeholder="Filtrer par utilisateur ..." column={column} togglefilter={togglefilter} containsSearch={true} />,
      });
    }

    columnsArray.push(
      {
        id: "Subject",
        Header: "Sujet",
        accessor: "subject",
        headerClassName: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "Service",
        Header: "Service",
        accessor: "service",
        headerClassName: "text-nowrap",
        filterType: FilterFieldTypes.Entity,
        getFilterValues: (pageIndex, pageSize, search) => API.getTicketIsoServices(pageIndex, pageSize, search),
        Filter: (column, togglefilter) => <TypeAheadColumnFilter placeholder="Filtrer par service ..." column={column} togglefilter={togglefilter} />,
      },
      {
        id: "Domain",
        Header: "Domaine",
        accessor: "domain",
        headerClassName: "text-nowrap",
        filterType: FilterFieldTypes.Entity,
        getFilterValues: (pageIndex, pageSize, search) => API.getTicketIsoServiceDomains(pageIndex, pageSize, search),
        Filter: (column, togglefilter) => <TypeAheadColumnFilter placeholder="Filtrer par domaine  ..." column={column} togglefilter={togglefilter} />,
      },
      {
        id: "OpenTime",
        Header: "Ouverture",
        accessor: "openTime",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.DateRange,
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.openTime)}</span>,
        Filter: (column, togglefilter) => <DateRangeColumnFilter column={column} togglefilter={togglefilter} />,
      },
      {
        id: "CloseTime",
        Header: "Fermeture",
        accessor: "closeTime",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.DateRange,
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.closeTime)}</span>,
        Filter: (column, togglefilter) => <DateRangeColumnFilter column={column} togglefilter={togglefilter} />,
      },
      {
        id: "Status",
        Header: "Statut",
        accessor: "status",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.Set,
        filterValues: statusFilterValues,
        Filter: (column, togglefilter) => <CheckBoxColumnFilter column={column} togglefilter={togglefilter} />,
      }
    );

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportTickets(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderHeaderAction = (viewFilter, scopeFilters, sortBy, apifilters) => {
    return (
        <>
        {!user.noSupport && 
            <Link to={UrlUtils.newticket()}>
              <Button color="primary" className="mb-3">
                <i className="bi bi-plus me-1"></i>
                <span>Nouvelle Demande</span>
              </Button>
            </Link>
        }
        <Button color="primary" onClick={() => onExportClick(viewFilter, scopeFilters, sortBy, apifilters)} className="ms-1 mb-3">
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderViewFilter = (setViewValue) => {
    var viewFilterValues = [{ id: "mine", name: "Mes demandes" }];

    if (user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) viewFilterValues.push({ id: "all", name: "Toutes les demandes" });

    return (
      <>
        <ViewFilter views={viewFilterValues} defaultView={"mine"} setSelectedValue={setViewValue} />
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return <>{(user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) && <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />}</>;
  };

  return (
    <div>
      <HeaderMenu title="Demandes"></HeaderMenu>
      <ContainerWrapper>
        <EntityList getColumns={initColumns} getEntities={API.getTickets} headerActions={renderHeaderAction} viewFiltersRenderer={renderViewFilter} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default TicketList;
