import { Button } from "reactstrap";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { API } from "../../../utils/API";
import DecimalUtils from "../../../utils/DecimalUtils";
import { DateUtils } from "../../../utils/DateUtils";
import ContainerWrapper from "../../shared/ContainerWrapper";
import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import EntityList from "../../shared/EntityList";
import MonthPeriodFilter from "../../shared/ScopeFilter/MonthPeriodFilter";

const PhoneRecordList = () => {
  const initColumns = (viewValue) => {
    let columnsArray = [];

    columnsArray.push({
      id: "InviteTime",
      Header: "Date",
      accessor: "inviteTime",
      headerClassName: "text-nowrap",
      className: "text-nowrap",
      Cell: ({ row }) => <span>{DateUtils.formatDateTime(row.original.inviteTime)}</span>,
      disableFilters: true,
    });

    columnsArray.push({
      id: "BillingEntityName",
      Header: "Entité de facturation",
      accessor: "billingEntityName",
      disableFilters: true,
    });

    columnsArray.push(
      {
        id: "CallerUri",
        Header: "Source",
        accessor: "callerUri",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "CalleeNumber",
        Header: "Destination",
        accessor: "calleeNumber",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "ZoneDisplay",
        Header: "Zone",
        accessor: "zoneDisplay",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "DurationDisplay",
        Header: "Duration",
        accessor: "durationDisplay",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: "Price",
        Header: "Prix",
        accessor: "price",
        headerClassName: "text-nowrap",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.price)}</span>,
        disableFilters: true,
        disableSortBy: true,
      }
    );

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportPhoneRecords(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderHeaderAction = (viewFilter, scopeFilters, sortBy, apifilters) => {
    return (
      <>
        <Button color="primary" onClick={() => onExportClick(viewFilter, scopeFilters, sortBy, apifilters)}>
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderViewFilter = (setViewValue) => {
    var now = new Date();
    var monthDate = new Date(now.getFullYear(), now.getMonth());
    return (
      <>
        <MonthPeriodFilter defaultDate={monthDate} setMonthPeriod={setViewValue} />
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return (
      <>
        <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="Conso tél"></HeaderMenu>
      <ContainerWrapper>
        <EntityList getColumns={initColumns} getEntities={API.getPhoneRecords} headerActions={renderHeaderAction} viewFiltersRenderer={renderViewFilter} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default PhoneRecordList;
