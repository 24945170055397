import { useState, useEffect } from "react";
import MonthInputPicker from "../Inputs/MonthInput";

export const MonthPeriodFilter = ({ className, defaultDate, setMonthPeriod }) => {
  const [date, setDate] = useState(defaultDate);

  useEffect(() => {
    setMonthPeriod(date);
  }, [date]);

  const onDateChanged = (value) => {
    setDate(value);
  };

  return <MonthInputPicker className={className} date={date} onDateChanged={onDateChanged} />;
};

export default MonthPeriodFilter;
