import React from "react";
import { Link } from "react-router-dom";
import { Button, Label } from "reactstrap";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { UrlUtils } from "../../../utils/UrlUtils";

const TicketRankDetail = ({ id, note, comment }) => {
  return (
    <div className="area-border">
      <div>
        <Label className="fw-bold">Votre Avis</Label>
      </div>
      <Rate defaultValue={note ?? 0} style={{ fontSize: 40 }} disabled allowClear={false} />
      {comment === null && <div className="mt-2 comment-break-word text-muted">Aucun commentaire</div>}
      {comment && <div className="mt-2 comment-break-word" dangerouslySetInnerHTML={{ __html: comment }}></div>}
      <div className="mt-2">
        <Link to={UrlUtils.ticketRank(id)}>
          <Button color="primary">{note === null ? "Évaluer la réponse" : "Modifier l'évaluation"}</Button>
        </Link>
      </div>
    </div>
  );
};

export default TicketRankDetail;
