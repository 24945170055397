import React from "react";
import { Table } from "reactstrap";

export const ReadOnlyTableCellWrapper = (props) => {
  return (
    <Table striped>
      <tbody>{props.children}</tbody>
    </Table>
  );
};

export default ReadOnlyTableCellWrapper;
