import React, { useState} from "react";
import { Input, Label, Button } from "reactstrap";

const CheckBoxColumnFilter = ({ column, togglefilter }) => {
  const { setFilter } = column;

  const [inputValues, setInputValues] = useState(column.filterValue ?? []);

  const updateFilter = () => {
    setFilter(inputValues);
    togglefilter();
  };

  const onInputValueChange = (value) => {
    let tempValues = [...inputValues];
    const index = tempValues.indexOf(value);
    if (index === -1) {
      tempValues.push(value);
    } else {
      tempValues.splice(index, 1);
    }
    setInputValues(tempValues);
  };

  const isChecked = (value) => {
    const index = inputValues.indexOf(value);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const clearFilter = () => {
    setInputValues([]);
    setFilter([]);
    togglefilter();
  };

  return (
    <>
      {column.filterValues && (
        <>
          <div className="mb-2">
            {column.filterValues.map((value) => (
              <div key={value.id}>
                <Input
                  id={`${column.id}-filter-${value.id}`}
                  className="me-1"
                  type="checkbox"
                  value={value.id}
                  onChange={(e) => onInputValueChange(e.target.value)}
                  checked={isChecked(value.id.toString())}
                />
                <Label for={`${column.id}-filter-${value.id}`}>{value.name}</Label>
              </div>
            ))}
          </div>
          <Button color="success" onClick={() => updateFilter()}>
            <span>Filtrer</span>
          </Button>
          {inputValues.length > 0 && (
            <Button onClick={() => clearFilter()} className="ms-1">
              <span>Effacer</span>
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default CheckBoxColumnFilter;
