import React from "react";
import { useTable, useExpanded } from "react-table";
import { Table } from "reactstrap";

export function getExpandableColumnHeader() {
  return {
    // Build our expander column
    id: "expander",
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <i {...getToggleAllRowsExpandedProps()} className={`bi ${isAllRowsExpanded ? "bi-dash-square" : "bi-plus-square"}`} style={{ cursor: "pointer" }}></i>
    ),
    Cell: ({ row }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="text-center">
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            <i className={`bi ${row.isExpanded ? "bi-dash-square" : "bi-plus-square"}`} style={{ cursor: "pointer" }}></i>
          </span>
        </div>
      ) : null,
  };
}

export const ExpandableTable = ({ columns, data, hasFooter }) => {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data,
    },
    useExpanded
  );

  return (
    <>
      <Table striped hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                    },
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps([
                      {
                        className: column.className,
                      },
                    ])}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </>
  );
};
