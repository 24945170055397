import { Label } from "reactstrap";
import { useField } from "formik";
import PhoneInput from "react-phone-number-input";
export const PhoneNumberInput = ({ label, name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const setValue = (helpers, value) => {
    helpers.setValue(value);
    // quick fix to trigger validation, otherwise validation is done on next compute
    setTimeout(() => helpers.setTouched(true));
  };

  return (
    <>
      <Label className="fw-bold" htmlFor={props.id || props.name}>
        {label}
      </Label>
      <PhoneInput
        {...field}
        className={`form-control ${meta.touched && meta.error ? "is-invalid" : null}`}
        placeholder="Entrer un numéro de téléphone"
        defaultCountry="FR"
        onChange={(o) => setValue(helpers, o)}
      />
      {meta.touched && meta.error ? <div className="d-block invalid-feedback">{meta.error}</div> : null}
    </>
  );
};

export default PhoneNumberInput;
