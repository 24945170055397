import React, { useState } from "react";
import { Button, InputGroup, InputGroupText } from "reactstrap";
import DateInput from "../Inputs/DateInput";

export const DateRangeColumnFilter = ({ column, togglefilter }) => {
  const { setFilter } = column;

  // const [inputValues, setInputValues] = useState(column.filterValue ?? { start: null, end: null });
  const [start, setStart] = useState(column.filterValue ? column.filterValue.start : null);
  const [end, setEnd] = useState(column.filterValue ? column.filterValue.end : null);

  const updateFilter = () => {
    if (start === null && end === null) setFilter(null);
    else setFilter({ start: start, end: end });
    togglefilter();
  };

  const onStartChanged = (value) => {
    setStart(value);
  };

  const onEndchanged = (value) => {
    setEnd(value);
  };

  const clearStart = () => {
    setStart(null);
  };

  const clearEnd = () => {
    setEnd(null);
  };

  const clearFilter = () => {
    clearStart();
    clearEnd();
    setFilter(null);
    togglefilter();
  };

  return (
    <>
      <div className="mb-2">
        <InputGroup>
          <InputGroupText style={{ width: "80px" }}>Entre le</InputGroupText>
          <div className="form-control">
            <DateInput date={start} onDateChanged={onStartChanged} />
          </div>
          <Button onClick={() => clearStart()}>
            <i className="bi bi-trash"></i>
          </Button>
        </InputGroup>

        <br />

        <InputGroup>
          <InputGroupText style={{ width: "80px" }}>Et le</InputGroupText>
          <div className="form-control">
            <DateInput date={end} onDateChanged={onEndchanged} />
          </div>
          <Button onClick={() => clearEnd()}>
            <i className="bi bi-trash"></i>
          </Button>
        </InputGroup>
      </div>
      <Button color="success" onClick={() => updateFilter()}>
        <span>Filtrer</span>
      </Button>
      {(start || end) && (
        <Button onClick={() => clearFilter()} className="ms-1">
          <span>Effacer</span>
        </Button>
      )}
    </>
  );
};
