import React from "react";
import { Autocomplete } from "../AutoComplete";

const TypeAheadColumnFilter = ({ column, togglefilter, placeholder }) => {
  const { filterValue, setFilter, getFilterValues } = column;

  const OnValueChanged = (value) => {
    setFilter(value);
    togglefilter();
  };

  return (
    <div>
      <Autocomplete
        className="w-100"
        inputPlaceholder={placeholder}
        itemToString={(option) => option?.name || ""}
        getMatches={getFilterValues}
        value={filterValue}
        onChange={(v) => OnValueChanged(v)}
      />
    </div>
  );
};

export default TypeAheadColumnFilter;
