import React, { useState} from "react";
import Error from "../../shared/Error";
import InputWrapper from "../../shared/Forms/InputWrapper";
import TextAreaInput from "../../shared/Forms/TextAreaInput";
import { Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const TicketComment = ({ id, updateMethod, commentRequired, color, buttonTitle, placeholder }) => {
  const [error, setError] = useState();

  const updateTicket = (values) => {
    const request = updateMethod(id, values);
    request
      .then((data) => {
        refreshCurrentPage();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const refreshCurrentPage = () => {
    window.location.reload(false);
  };

  return (
    <div className={`area-border border-${color}`}>
      {error && <Error error={error} />}
      {
        <Formik
          initialValues={{
            comment: "",
          }}
          validationSchema={Yup.object({
            comment: commentRequired ? Yup.string().required("Requis") : Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            updateTicket(values);
            setSubmitting(false);
          }}
        >
          {(props) => (
            <Form>
              <InputWrapper>
                <TextAreaInput name="comment" rows={2} placeholder={placeholder} />
              </InputWrapper>
              <div className="mt-3">
                <Button color={color} type="submit">
                  {buttonTitle}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </div>
  );
};

export default TicketComment;
