const ExpandableRow = ({ canCollapse, collapsed, toggle, columns, details }) => {
  return (
    <>
      <tr>
        {canCollapse && (
          <td className="text-center" onClick={() => toggle()}>
            <i className={`bi ${collapsed ? "bi-plus-square" : "bi-dash-square"}`} style={{ cursor: "pointer" }}></i>
          </td>
        )}
        {!canCollapse && <td></td>}
        {columns()}
      </tr>
      {!collapsed && details()}
    </>
  );
};

export default ExpandableRow;
