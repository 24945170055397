import { useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import ContainerWrapper from "../../shared/ContainerWrapper";
import Error from "../../shared/Error";
import { DateUtils } from "../../../utils/DateUtils";

const EmailDetails = () => {
  const { id } = useParams();
  const { emailId } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    const parsedEmailId = parseInt(emailId, 10);
    if (isNaN(parsedId) || parsedId <= 0 || isNaN(parsedEmailId) || parsedEmailId <= 0) {
      if (isNaN(parsedId) || parsedId <= 0) setError({ message: `Invalid Id ${id}` });
      else setError({ message: `Invalid EmailId ${emailId}` });
      setLoading(false);
    } else {
      const request = API.getTicketEmail(parsedId, parsedEmailId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, emailId, setLoading]);

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading></Loading>}
      {!loading && entity && !error && (
        <>
          <div className="container-blue py-3">
            <div className="container">
              <span className="fw-bold fs-4">{entity.subject}</span>
              <br />
              <span>{entity.from}</span>
              <br />
              <span>{DateUtils.formatDateTime(entity.ctime)}</span>
            </div>
          </div>
          <ContainerWrapper>{<div dangerouslySetInnerHTML={{ __html: entity.body }}></div>}</ContainerWrapper>
        </>
      )}
    </>
  );
};

export default EmailDetails;
