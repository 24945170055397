import moment from "moment";
import { DateUtils } from "./DateUtils";

export const FilterFieldTypes = Object.freeze({
  Text: Symbol("Text"),
  Entity: Symbol("Entity"),
  Set: Symbol("Set"),
  DateRange: Symbol("DateRange"),
});

function createError(status, exception, message) {
  console.error(exception);
  return { error: { status, message } };
}

function buildQuerySearchTypeAhead(pageIndex, pageSize, searchString, searchContains) {
  let offset = pageIndex * pageSize;

  let queryString = `offset=${offset}&top=${pageSize}&ComputeHasMoreRows=false&ComputeHitCount=false`;

    if (searchString) {
      queryString += searchContains ? `&Contains=${searchString}` : `&StartWith=${searchString}`;
  }

  return queryString;
}

function buildQueryString(pageIndex, pageSize, computeHitCount, sortedColumn, sortDirection, filters, search) {
  let offset = pageIndex * pageSize;

  let queryString = `offset=${offset}&top=${pageSize}`;

  if (computeHitCount) queryString += "&ComputeHasMoreRows=false&ComputeHitCount=true";

  if (sortedColumn) {
    queryString += `&Sorting=${(sortDirection ? "-" : "") + sortedColumn}`;
  }

  if (filters && filters.length) {
    for (let i = 0; i < filters.length; i++) {
      queryString += `&Filter.${filters[i].field}=${filters[i].value}`;
    }
  }

  if (search) {
    queryString += `&Search=${search}`;
  }
  return queryString;
}

function buildExportQueryString(sortedColumn, sortDirection, filters) {
  return buildQueryString(0, 1000, true, sortedColumn, sortDirection, filters);
}

function addBillingEnityScope(query, billingEntityId) {
  if (query && billingEntityId) {
    return (query += `&BillingEntityId=${billingEntityId}`);
  }
  return query;
}

const CONSTANTS = {
  API_BASE_URL: "/api/",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

async function download(response) {
  let blob = await response.blob();
  let fileName = response.headers
    .get("content-disposition")
    .split(";")
    .find((n) => n.includes("filename="))
    .replace("filename=", "")
    .replaceAll('"', "")
    .trim();

  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(downloadUrl);
}

const request = (url, method, body, isJson, isFile) => {
  // console.log(`call api url: ${url}`);
  const abortController = new AbortController();

  const options = {
    method,
    headers: isJson && !isFile ? jsonHeaders : undefined,
    body: body && isJson ? JSON.stringify(body) : body,
    signal: abortController.signal,
  };

  const promise = new Promise((resolve, reject) => {
    fetch(CONSTANTS.API_BASE_URL + url, options)
      .then((response) => {
        if (response.ok) {
          if (isFile) {
            download(response);
          } else {
            response
              .json()
              .then((data) => {
                // console.log(data);
                resolve(data);
              })
              .catch(() => resolve({ message: "Unable to parse response as json" }));
          }
        } else {
          response
            .json()
            .then((error) => reject({ status: response.status, message: response.statusText, error }))
            .catch(() => reject({ status: response.status, message: response.statusText }));
        }
      })
      .catch((error) => {
        if (error && error.name === "AbortError") resolve();
        else reject(error);
      });
  });

  promise.aborted = false;
  promise.abort = () => {
    abortController.abort();
    promise.aborted = true;
  };

  return promise;
};

const get = (url, isJson = true, isFile = false) => request(url, CONSTANTS.GET, null, isJson, isFile);
const create = (url, body, isJson = true) => request(url, CONSTANTS.POST, body, isJson, false);
const update = (url, body, isJson = true) => request(url, CONSTANTS.PUT, body, isJson, false);
const remove = (url, isJson = true) => request(url, CONSTANTS.DELETE, null, isJson, false);

export const getAPIFilter = (filterFieldType, fieldId, filterValue) => {
  if (!fieldId || !filterFieldType) return null;
  switch (filterFieldType) {
    case FilterFieldTypes.Entity:
      if (filterValue) return { field: fieldId, value: filterValue.id };
      else return null;
    case FilterFieldTypes.Set:
      if (Array.isArray(filterValue) && filterValue.length > 0) return { field: fieldId, value: `(${filterValue.join(",")})` };
      else return null;
    case FilterFieldTypes.DateRange:
      if (filterValue.start && filterValue.end) {
        let start = DateUtils.convertDateFromIso(filterValue.start);
        let end = DateUtils.convertDateFromIso(filterValue.end);
        return { field: fieldId, value: `(${start}..${end})` };
      } else if (filterValue.start) {
        let start = DateUtils.convertDateFromIso(filterValue.start);
        return { field: fieldId, value: `(${start}..` };
      } else if (filterValue.end) {
        let end = DateUtils.convertDateFromIso(filterValue.end);
        return { field: fieldId, value: `..${end})` };
      } else {
        return null;
      }
    default:
      console.error(`ApiFilter : Unexpected filterType ${filterFieldType}`);
      return null;
  }
};

export const API = {
  //SessionsController
  getUserSession: () => get("sessions/current"),
  getUserBillingEntities: (pageIndex, pageSize, search, searchContains) => {
      let queryString = buildQuerySearchTypeAhead(pageIndex, pageSize, search, searchContains);
    return get(`sessions/billingentities?${queryString}`);
  },

  //ImpersonationController
  getImpersonateUserAccounts: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, scopeFilter, filters) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    if (scopeFilter) {
      if (scopeFilter.clientCode) queryString += `&ClientCode=${scopeFilter.clientCode}`;
      if (scopeFilter.userAccountName) queryString += `&UserAccountName=${scopeFilter.userAccountName}`;
    }
    return get(`impersonation/useraccounts?${queryString}`);
  },
  impersonateUser: (id) => get(`impersonation/start/${id}`),
  stopImpersonateUser: (id) => get(`impersonation/stop`),

  //IsoservicesController
  getIsoServices: () => get(`isoservices`),
  getIsoServiceDomains: (serviceId) => get(`isoservices/${serviceId}/domains`),

  //AssetsController
  getNews: () => get("assets/news"),
  getLegalNotices: () => get("assets/legalnotices"),

  //UseraccountsController
  getUserAccounts: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, scopeFilter, filters) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    return get(`useraccounts?${queryString}`);
  },
  getUserAccount: (id) => get(`useraccounts/${id}`),
  getUserAccountServices: (id) => get(`useraccounts/${id}/services`),

  //DevicesController
  getDevices: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);
    return get(`devices?View=${viewFilter}&${queryString}`);
  },
  exportDevices: async (sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);
    return get(`devices/export?View=${viewFilter}&${queryString}`, false, true);
  },

  //PhoneBillingRecordController
  getPhoneRecords: (pageIndex, pageSize, sortedColumn, sortDirection, monthPeriod, billingEntityId, filters) => {
    if (monthPeriod === null) return createError(400, "Periode non renseignée");
    var date = moment(monthPeriod);
    if (date === null) return createError(400, "Periode mal renseignée");
    var isoDate = DateUtils.convertDateToIso(monthPeriod);
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`phonebillingrecords?MonthPeriod=${isoDate}&${queryString}`);
  },
  exportPhoneRecords: async (sortedColumn, sortDirection, monthPeriod, billingEntityId, filters) => {
    if (monthPeriod === null) return createError(400, "Periode non renseignée");
    var date = moment(monthPeriod);
    if (date === null) return createError(400, "Periode mal renseignée");
    var isoDate = DateUtils.convertDateToIso(monthPeriod);

    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`phonebillingrecords/export?MonthPeriod=${isoDate}&${queryString}`, false, true);
  },

  //QuotesController
  getQuotes: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, billingEntityId) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, null);
    return get(`quotes?View=${viewFilter}&${queryString}`);
  },
  exportQuotes: async (sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`quotes/export?View=${viewFilter}&${queryString}`, false, true);
  },
  getQuote: (id) => get(`quotes/${id}`),
  printQuote: async (id) => {
    return get(`quotes/${id}/print`, false, true);
  },

  //BillsController
  getBills: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, billingEntityId) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, null);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`bills?View=${viewFilter}&${queryString}`);
  },
  exportBills: async (sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`bills/export?View=${viewFilter}&${queryString}`, false, true);
  },
  getBill: (id) => get(`bills/${id}`),
  printBill: async (id) => {
    return get(`bills/${id}/print`, false, true);
  },

  //TicketsController
  getTickets: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`tickets?View=${viewFilter}&${queryString}`);
  },
  exportTickets: async (sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);

    return get(`tickets/export?View=${viewFilter}&${queryString}`, false, true);
  },
  getTicket: (id) => get(`tickets/${id}`),
  getTicketEmail: (id, emailId) => get(`tickets/${id}/emails/${emailId}`),
  getTicketUserAccounts: (pageIndex, pageSize, search, searchContains) => {
    let queryString = buildQuerySearchTypeAhead(pageIndex, pageSize, search, searchContains);
    return get(`tickets/useraccounts?${queryString}`);
  },
  getTicketIsoServices: (pageIndex, pageSize, search, searchContains) => {
    let queryString = buildQuerySearchTypeAhead(pageIndex, pageSize, search, searchContains);
    return get(`tickets/services?${queryString}`);
  },
  getTicketIsoServiceDomains: (pageIndex, pageSize, search, searchContains) => {
    let queryString = buildQuerySearchTypeAhead(pageIndex, pageSize, search, searchContains);
    return get(`tickets/servicedomains?${queryString}`);
  },

  createTicket: (o) => create("tickets", o, false),
  donwloadTicketFile: async (id, fileId) => {
    return get(`tickets/${id}/files/${fileId}`, false, true);
  },
  updateTicketImportFile: (id, o) => update(`tickets/${id}/files`, o, false),
  updateTicketAddComment: (id, o) => update(`tickets/${id}/comment`, o),
  closetTicket: (id, o) => update(`tickets/${id}/close`, o),
  reopentTicket: (id, o) => update(`tickets/${id}/reopen`, o),
  rankTicket: (id, o) => update(`tickets/${id}/rank`, o),

  deleteTicketFile: (id, fileId) => remove(`tickets/${id}/files/${fileId}`, false),

  //ServicesController
  getServiceDetails: (billingEntityId, userId) => get(`services?BillingEntityId=${billingEntityId}&UserId=${userId}`),
  getServiceDetailsUsers: (pageIndex, pageSize, search, searchContains) => {
    let queryString = buildQuerySearchTypeAhead(pageIndex, pageSize, search, searchContains);
    return get(`services/users?${queryString}`);
  },
  getUserServices: (billingEntityId) => get(`services/foruser?BillingEntityId=${billingEntityId}`),

  //SDAsController
  getSDAs: (pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildQueryString(pageIndex, pageSize, true, sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);
    return get(`sdas?${queryString}`);
  },
  exportSDAs: async (sortedColumn, sortDirection, viewFilter, billingEntityId, filters) => {
    let queryString = buildExportQueryString(sortedColumn, sortDirection, filters);
    queryString = addBillingEnityScope(queryString, billingEntityId);
    return get(`sdas/export?${queryString}`, false, true);
  },

  //ContactsController
  getDecisionsMakers: () => get("contacts/decisionmakers"),
  getClientContacts: () => get("contacts/clientcontacts"),
  getClientOwner: () => get("contacts/clientowner"),

  //UserQuoteController
  createUserQuote: (o) => create("userquote", o),
};
