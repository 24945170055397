import React, { useEffect } from "react";
import { useState } from "react";
import { API } from "../../utils/API";
import { UserProvider } from "../../utils/UserContext";
import { Error } from "../shared/Error";

export const Authenticate = ({ children }) => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const request = API.getUserSession();
    request
      .then((data) => {
        if (!request.aborted) {
          setUser(data);
          setError(null);
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setUser(null);
          setError(error);
        }
      });

    return request.abort;
  }, []);

  return (
    <>
      {error && <Error error={error} />}
      {user && <UserProvider value={user}>{children}</UserProvider>}
    </>
  );
};
