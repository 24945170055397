import { useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import ContainerWrapper from "../../shared/ContainerWrapper";
import TicketRankForm from "./TicketRankForm";
import Error from "../../shared/Error";
import "./TicketDetail.scss";

const TicketRank = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setError({ message: `Invalid Id ${id}` });
      setLoading(false);
    } else {
      const request = API.getTicket(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  const renderForms = () => {
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-7">
            <TicketRankForm id={id} note={entity.satisfactionNote} comment={entity.satisfactionComment} ratingValues={entity.satisfactionNoteValues} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-5">
            <ReadOnlyTableCellWrapper>
              <ReadOnlyTableCell fieldTitle="Code" fieldValue={entity.code} bold={true} />
              <ReadOnlyTableCell fieldTitle="Ouvert Part" fieldValue={entity.openedBy} />
              <ReadOnlyTableCell fieldTitle="Sujet" fieldValue={entity.subject} />
              <ReadOnlyTableCell fieldTitle="Ouverture" fieldValue={entity.openTime} fieldType="datetime" />
              <ReadOnlyTableCell fieldTitle="Fermeture" fieldValue={entity.closeTime} fieldType="datetime" />
            </ReadOnlyTableCellWrapper>
          </div>
        </div>
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return renderForms();
    }
  };

  return (
    <>
      <HeaderMenu title="Évaluation de la demande"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

export default TicketRank;
