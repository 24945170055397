import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { API } from "../../../utils/API";
import Error from "../../shared/Error";
import InputWrapper from "../../shared/Forms/InputWrapper";
import TextAreaInput from "../../shared/Forms/TextAreaInput";
import RateStarInput from "../../shared/Forms/RateStarInput";
import { Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { UrlUtils } from "../../../utils/UrlUtils";

const TicketRankForm = ({ id, note, comment, ratingValues }) => {
  const [error, setError] = useState();
  const [backToDetail, setBackToDetail] = useState(false);

  const updateTicket = (values) => {
    const request = API.rankTicket(id, values);
    request
      .then((data) => {
        setBackToDetail(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  if (backToDetail) {
    return <Redirect to={UrlUtils.ticketDetail(id)} />;
  } else {
    return (
      <div className="area-border">
        {error && <Error error={error} />}
        {
          <Formik
            initialValues={{
              satisfactionNote: note ?? 0,
              satisfactionComment: comment ?? "",
            }}
            validationSchema={Yup.object({
              satisfactionNote: Yup.number().min(1, "Veuillez renseigner une note avant d'enregistrer"),
              satisfactionComment: Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              updateTicket(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <InputWrapper>
                  <RateStarInput label="Votre avis" name="satisfactionNote" defaultValue={props.initialValues.satisfactionNote} size={80} count={ratingValues.length} displayValues={ratingValues} />
                </InputWrapper>
                <InputWrapper>
                  <TextAreaInput label="Commentaire" name="satisfactionComment" rows={10} placeholder="Commentaire (optionnel)" />
                </InputWrapper>
                <div className="mt-3">
                  <Button className="me-3" color="success" type="submit">
                    Enregistrer
                  </Button>
                  <Link to={UrlUtils.ticketDetail(id)}>
                    <Button color="danger" type="submit">
                      Annuler
                    </Button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        }
      </div>
    );
  }
};

export default TicketRankForm;
