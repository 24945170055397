import { Link, useLocation } from "react-router-dom";
import { NavLink } from "reactstrap";

export const NavMenuItemLink = ({ children, textColor, ...props }) => {
  const location = useLocation();

  let isActive = location.pathname.startsWith(props.to);
  let className = "text-" + (textColor ?? "dark");
  if (isActive) {
    className = " active";
  }

  return (
    <NavLink tag={Link} {...props} className={className}>
      {children}
    </NavLink>
  );
};

export default NavMenuItemLink;
