import React from "react";
import { DecimalUtils } from "../../../utils/DecimalUtils";

const QuoteServiceDetail = ({ serviceDetail, taxRate }) => {
  return (
    <>
      <thead>
        <tr>
          <th className="typeGroupHeader" colSpan={2}>
            {serviceDetail.name}
          </th>
          <th className="typegroup">Qté.</th>
          <th className="typegroup"></th>
          <th className="typegroup text-end">Prix unit.</th>
          <th className="typegroup text-end">Montant HT</th>
          <th className="typegroup text-end">{`TVA ${taxRate} %`}</th>
          <th className="typegroup text-end">Montant TTC</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={8}></td>
        </tr>
        {serviceDetail.groups.map((categoryDetail, index) => (
          <React.Fragment key={index}>
            <tr className="catGroup">
              <td className="catGroupHeader fw-bold" colSpan={2}>
                {categoryDetail.name}
              </td>
              <td className="catGroup" colSpan={6}></td>
            </tr>
            {categoryDetail.items.map((categoryItem, itemIndex) => (
              <React.Fragment key={itemIndex}>
                <tr>
                  <td></td>
                  <td className="itemLabel">{categoryItem.name}</td>
                  <td>{DecimalUtils.displayNumber(categoryItem.qty, 2, true)}</td>
                  <td></td>
                  <td className="text-end">{DecimalUtils.displayPrice(categoryItem.unitPrice, 2)}</td>
                  <td className="text-end">{DecimalUtils.displayPrice(categoryItem.totalHT, 2)}</td>
                  <td className="text-end">{DecimalUtils.displayPrice(categoryItem.tva, 2)}</td>
                  <td className="text-end">{DecimalUtils.displayPrice(categoryItem.totalTTC, 2)}</td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td colSpan={4}></td>
              <td className="subTotalHeader text-end">Sous-Total</td>
              <td className="subTotal text-end fw-bold">{DecimalUtils.displayPrice(categoryDetail.totalHT, 2)}</td>
              <td className="subTotal text-end fw-bold">{DecimalUtils.displayPrice(categoryDetail.tva, 2)}</td>
              <td className="subTotal text-end fw-bold">{DecimalUtils.displayPrice(categoryDetail.totalTTC, 2)}</td>
            </tr>
          </React.Fragment>
        ))}
        <tr>
          <td colSpan={8}></td>
        </tr>
        <tr>
          <td colSpan={4}></td>
          <td className="totalHeader text-end">Total</td>
          <td className="total text-end fw-bold">{DecimalUtils.displayPrice(serviceDetail.totalHT, 2)}</td>
          <td className="total text-end fw-bold">{DecimalUtils.displayPrice(serviceDetail.tva, 2)}</td>
          <td className="total text-end fw-bold">{DecimalUtils.displayPrice(serviceDetail.totalTTC, 2)}</td>
        </tr>
      </tbody>
    </>
  );
};

export default QuoteServiceDetail;
