export const UrlUtils = {
  billDetail: (id) => {
    return `/bills/${id}`;
  },
  quoteDetail: (id) => {
    return `/quotes/${id}`;
  },
  ticketDetail: (id) => {
    return `/tickets/${id}`;
  },
  ticketEmail: (id, emailId) => {
    return `/tickets/${id}/email/${emailId}`;
  },
  ticketRank: (id) => {
    return `/tickets/${id}/rank`;
  },
  userDetail: (id) => {
    return `/useraccounts/${id}`;
  },
  bills: () => {
    return `/bills`;
  },
  quotes: () => {
    return `/quotes`;
  },
  tickets: () => {
    return `/tickets`;
  },
  services: () => {
    return `/services`;
  },
  newUser: () => {
    return `/newuser`;
  },
  newticket: () => {
    return `/tickets/new`;
  },
};
