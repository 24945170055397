import React from "react";
import { DateUtils } from "../../utils/DateUtils";
import DecimalUtils from "../../utils/DecimalUtils";

const ReadOnlyTableCell = ({ fieldTitle, fieldValue, fieldType = "string", bold = false }) => {
  const getValue = () => {
    if (fieldValue !== undefined) {
      if (fieldType === "date") {
        return DateUtils.formatDate(fieldValue);
      } else if (fieldType === "datetime") {
        return DateUtils.formatDateTime(fieldValue);
      } else if (fieldType === "bool") {
        return fieldValue === true ? "Oui" : "Non";
      } else if (fieldType === "price") {
        return DecimalUtils.displayPrice(fieldValue, 2);
      } else {
        return fieldValue;
      }
    }
    return null;
  };

  return (
    <tr>
      <td className={bold ? "fw-bold" : ""}>{fieldTitle}</td>
      <td className={bold ? "fw-bold" : ""}>{getValue()}</td>
    </tr>
  );
};

export default ReadOnlyTableCell;
