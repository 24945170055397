import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { HeaderMenu } from "../navigation/HeaderMenu";
import { API } from "../../utils/API";
import "./ServiceList.scss";
import "react-phone-number-input/style.css";
import ContainerWrapper from "../shared/ContainerWrapper";
import ContainerHeaderBar from "../shared/ContainerHeaderBar";
import Error from "../shared/Error";
import InputWrapper from "../shared/Forms/InputWrapper";
import TextInput from "../shared/Forms/TextInput";
import CheckBoxList from "../shared/Forms/CheckBoxList";
import { PhoneNumberInput } from "../shared/Forms/PhoneNumberInput";
import { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Loading } from "../shared/Loading";
import { UrlUtils } from "../../utils/UrlUtils";
import TypeAheadInput from "../shared/Forms/TypeAheadInput";

const NewUserQuote = () => {
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(false);
  const [backToList, setBackToList] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesLoaded, setServicesLoaded] = useState(false);
  const [selectedBillingEntity, setSelectedBillingEntity] = useState(null);

  useEffect(() => {
    if (!servicesLoaded && selectedBillingEntity) {
      const request = API.getUserServices(selectedBillingEntity.id);
      request
        .then((data) => {
          if (!request.aborted) {
            setServices(data);
            setServicesLoaded(true);
            setError(null);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setServices(null);
            setServicesLoaded(true);
            setError(error);
          }
        });
    }
  }, [servicesLoaded, selectedBillingEntity]);

  const onBillingEntityChange = (selectedValue, formikHandlers) => {
    formikHandlers.setFieldValue("services", []);
    setServicesLoaded(false);
    setSelectedBillingEntity(selectedValue);
  };

  const submitCreateUser = (values) => {
    values.billingEntityId = values.billingEntity.id;
    const request = API.createUserQuote(values);
    request
      .then((data) => {
        setShowModal(false);
        goBackToList();
      })
      .catch((error) => {
        setShowModal(false);
        setError(error);
      });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const goBackToList = () => {
    setBackToList(true);
  };

  const renderServicesSummary = (selectedServices) => {
    if (selectedServices && selectedServices.length > 0) {
      return (
        <>
          <span>Abonnements :</span>
          <br />
          <ul>
            {selectedServices.map((selectedService, index) => (
              <li key={index}>{services.filter((o) => o.id === selectedService)[0].name}</li>
            ))}
          </ul>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (backToList) {
    return <Redirect to={UrlUtils.services()} />;
  } else {
    return (
      <div>
        <HeaderMenu title="Nouvel utilisateur"></HeaderMenu>
        <ContainerWrapper>
          {error && <Error error={error} />}

          <ContainerHeaderBar
            rightContent={
              <>
                <Link to={UrlUtils.services()}>
                  <Button color="danger" className="mb-3">
                    <span>Annuler</span>
                  </Button>
                </Link>
              </>
            }
          />

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              phoneNumber: "",
              billingEntity: null,
              services: [],
            }}
            validationSchema={Yup.object({
              billingEntity: Yup.object()
                .shape({
                  id: Yup.number(),
                  name: Yup.string(),
                })
                .nullable()
                .required("Requis"),
              firstName: Yup.string().required("Requis"),
              lastName: Yup.string().required("Requis"),
              phoneNumber: Yup.string()
                .required("Requis")
                .test("Format", "Le numéro de téléphone est invalide", (val) => val && typeof val === "string" && isValidPhoneNumber(val)),
              emailAddress: Yup.string().email("Email est invalide").required("Requis"),
              services: Yup.array().min(1, "Au moins un service est requis"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setShowModal(true);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <InputWrapper>
                  <TypeAheadInput
                    label="Entité de facturation"
                    name="billingEntity"
                    getValues={API.getUserBillingEntities}
                    placeHolder={"Sélectionner une entité de facturation"}
                    onChange={(value) => onBillingEntityChange(value, props)}
                    pageSize={200} 
                    containsSearch={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput label="Nom" name="lastName" type="text" />
                </InputWrapper>
                <InputWrapper>
                  <TextInput label="Prénom" name="firstName" type="text" />
                </InputWrapper>
                <InputWrapper>
                  <PhoneNumberInput label="Téléphone" name="phoneNumber" />
                </InputWrapper>
                <InputWrapper>
                  <TextInput label="Email" name="emailAddress" type="email" />
                </InputWrapper>
                <InputWrapper>
                  {servicesLoaded && <CheckBoxList label="Abonnements" name="services" values={services} />}
                  {!servicesLoaded && selectedBillingEntity && <Loading></Loading>}
                </InputWrapper>
                <div className="mt-3">
                  <Button color="success" type="submit">
                    Créer
                  </Button>
                </div>
                {showModal && (
                  <Modal isOpen={showModal} toggle={() => toggleModal()}>
                    <ModalHeader toggle={() => toggleModal()}>Résumé</ModalHeader>
                    <ModalBody>
                      <span>{`Entité de facturation : ${props.values.billingEntity.name}`}</span>
                      <br />
                      <span>{`Nom : ${props.values.lastName}`}</span>
                      <br />
                      <span>{`Prénom : ${props.values.firstName}`}</span>
                      <br />
                      <span>{`Téléphone : ${formatPhoneNumber(props.values.phoneNumber)}`}</span>
                      <br />
                      <span>{`Email : ${props.values.emailAddress}`}</span>
                      <br />
                      {renderServicesSummary(props.values.services)}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="success" onClick={() => submitCreateUser(props.values)}>
                        Confirmer
                      </Button>{" "}
                      <Button color="secondary" onClick={() => toggleModal()}>
                        Annuler
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </Form>
            )}
          </Formik>
        </ContainerWrapper>
      </div>
    );
  }
};

export default NewUserQuote;
