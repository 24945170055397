import { createContext, useContext } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ children, value }) => <UserContext.Provider value={value}>{children}</UserContext.Provider>;

export const useUser = () => {
  const context = useContext(UserContext);
  if (context == null) {
    throw new Error("useUser must be used within a UserContext");
  }
  return context;
};
