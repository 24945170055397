import { Input, Label, FormFeedback } from "reactstrap";
import { useField } from "formik";

const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && (
        <Label className="fw-bold" htmlFor={props.id || props.name}>
          {label}
        </Label>
      )}
      <Input type="textarea" invalid={meta.touched && meta.error ? true : false} className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </>
  );
};

export default TextAreaInput;
