import React, { useRef } from "react";
import useOnClickOutside from "../ClickOutsideHook";

const ColumnFilterWrapper = ({ column, activeFilter, filterChangeCb, ...props }) => {
  const ref = useRef();

  const toggleFilter = () => {
    if (activeFilter === column.id) filterChangeCb(null);
    else filterChangeCb(column.id);
  };

  const filterHasValue = () => {
    return column.filterValue && (!Array.isArray(column.filterValue) || column.filterValue.length !== 0);
  };

  useOnClickOutside(ref, () => toggleFilter());

  return (
    <>
      <i className={`bi ${filterHasValue() ? "bi-funnel-fill text-primary" : "bi-funnel"}`} style={{ cursor: "pointer" }} onClick={() => toggleFilter()}></i>
      {activeFilter === column.id && (
        <div className="filter-menu p-2" ref={ref}>
          {column.Filter(column, toggleFilter)}
        </div>
      )}
    </>
  );
};

export default ColumnFilterWrapper;
