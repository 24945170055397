import React from "react";
import { DecimalUtils } from "../../../utils/DecimalUtils";
import BillRelatedItem from "./BillRelatedItem";
import { DateUtils } from "../../../utils/DateUtils";

const BillSettlements = ({ settlements }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.date)}</span>,
        Footer: <span className="fw-bold">Total</span>,
      },
      {
        Header: "Référence",
        accessor: "code",
      },
      {
        id: "settlement-empty-1",
        Cell: ({ row }) => <></>,
      },
      {
        id: "settlement-empty-2",
        Cell: ({ row }) => <></>,
      },
      {
        Header: "Montant TTC",
        accessor: "amount",
        className: "text-end",
        headerClassName: "text-end",
        footerClassName: "text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.amount)}</span>,
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.amount + sum, 0), [info.rows]);

          return <span className="fw-bold">{DecimalUtils.displayPrice(total)}</span>;
        },
      },
    ],
    []
  );

  return (
    <div className="pt-2">
      <BillRelatedItem columns={columns} data={settlements} title="Règlements" />
    </div>
  );
};

export default BillSettlements;
