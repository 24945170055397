import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { API } from "../../utils/API";
import Error from "../shared/Error";
import HeaderMenu from "../navigation/HeaderMenu";
import ContainerWrapper from "../shared/ContainerWrapper";
import EntityList from "../shared/EntityList";
import { useUser } from "../../utils/UserContext";
import ImpersonateScopeFilter from "../shared/ScopeFilter/ImpersonateScopeFIlter";
import ReadOnlyTableCellWrapper from "../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../shared/ReadOnlyTableCell";

const Impersonate = () => {
  const user = useUser();
  const [userToImpersonate, setUserToImpersonate] = useState();
  const [error, setError] = useState();

  const initColumns = (viewFilter) => {
    let columnsArray = [];

    columnsArray.push({
      id: "Code",
      Header: "Ref",
      accessor: "code",
      headerClassName: "text-nowrap",
      className: "text-nowrap",
      Cell: ({ row }) => (
        <span className="customlink" onClick={() => setUserToImpersonate(row.original.id)}>
          {row.original.code}
        </span>
      ),
      disableFilters: true,
    });

    columnsArray.push(
      {
        id: "FirstName",
        Header: "Prénom",
        accessor: "firstName",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "LastName",
        Header: "Nom",
        accessor: "lastName",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "Email",
        Header: "Email",
        accessor: "email",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "ClientCode",
        Header: "Client",
        accessor: "clientCode",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      }
    );

    return columnsArray;
  };

  useEffect(() => {
    if (userToImpersonate) {
      const request = API.impersonateUser(userToImpersonate);
      request
        .then((data) => {
          if (!request.aborted) {
            setError(null);
            refreshCurrentPage();
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setError(error);
          }
        });
    }
  }, [userToImpersonate]);

  const stopImpersonateUser = () => {
    const request = API.stopImpersonateUser();
    request
      .then((data) => {
        if (!request.aborted) {
          setError(null);
          refreshCurrentPage();
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setError(error);
        }
      });
  };

  const renderHeaderAction = (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    return <></>;
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return <ImpersonateScopeFilter setSelectedValue={setScopeFilters} setError={setError} />;
  };

  const refreshCurrentPage = () => {
    window.location.reload(false);
  };

  const renderUserImpersonatedData = () => {
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">Identité utilisée :</h5>
            <ReadOnlyTableCellWrapper className="mb-3">
              <ReadOnlyTableCell fieldTitle="Compte" fieldValue={user.account} fieldType="string" bold={true} />
              <ReadOnlyTableCell fieldTitle="Nom" fieldValue={user.lastName} />
              <ReadOnlyTableCell fieldTitle="Prénom" fieldValue={user.firstName} />
              <ReadOnlyTableCell fieldTitle="Email" fieldValue={user.upn} />
              <ReadOnlyTableCell fieldTitle="Site" fieldValue={user.siteName} />
              <ReadOnlyTableCell fieldTitle="Client" fieldValue={user.clientCode} />
            </ReadOnlyTableCellWrapper>

            <Button color="danger" onClick={() => stopImpersonateUser()}>
              <span>Ne plus impersoner</span>
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="Impersonation"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error></Error>}
        {user && user.isImpersonating && renderUserImpersonatedData()}
        <br />
        <EntityList getColumns={initColumns} getEntities={API.getImpersonateUserAccounts} headerActions={renderHeaderAction} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default Impersonate;
