import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { HeaderMenu } from "../../navigation/HeaderMenu";

import { API } from "../../../utils/API";
import { UrlUtils } from "../../../utils/UrlUtils";
import { DecimalUtils } from "../../../utils/DecimalUtils";
import { useUser } from "../../../utils/UserContext";

import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import ViewFilter from "../../shared/ScopeFilter/ViewFilter";
import ContainerWrapper from "../../shared/ContainerWrapper";
import EntityList from "../../shared/EntityList";

const QuoteList = () => {
  const user = useUser();

  const viewFilterValues = [
    { id: "deploying", name: "Devis à déployer" },
    { id: "deployed", name: "Devis déployés" },
    { id: "all", name: "Toutes" },
  ];

  const initColumns = (viewFilter) => {
    let columnsArray = [];

    columnsArray.push({
      id: "Code",
      Header: "Ref",
      accessor: "code",
      className: "text-nowrap",
      Cell: ({ row }) => <Link to={UrlUtils.quoteDetail(row.original.id)}>{row.original.code}</Link>,
      disableFilters: true,
    });

    if (user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) {
      columnsArray.push({
        id: "BillingEntityName",
        Header: "Entité de facturation",
        accessor: "billingEntityName",
        disableFilters: true,
      });
    }

    columnsArray.push(
      {
        id: "Object",
        Header: "Objet",
        accessor: "object",
        disableFilters: true,
      },
      {
        id: "Status",
        Header: "Statut",
        accessor: "status",
        disableFilters: true,
      },
      {
        id: "MonthlyNetAmount",
        Header: "Mensualités HT",
        accessor: "monthlyNetAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.monthlyNetAmount)}</span>,
        disableFilters: true,
      },
      {
        id: "MonthlyGrossAmount",
        Header: "Mensualités TTC",
        accessor: "monthlyGrossAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.monthlyGrossAmount)}</span>,
        disableFilters: true,
      },
      {
        id: "OneOffNetAmount",
        Header: "One-off HT",
        accessor: "oneOffNetAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.oneOffNetAmount)}</span>,
        disableFilters: true,
      },
      {
        id: "OneOffGrossAmount",
        Header: "One-off TTC",
        accessor: "oneOffGrossAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.oneOffGrossAmount)}</span>,
        disableFilters: true,
      }
    );

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportQuotes(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderHeaderAction = (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    return (
      <>
        <Button color="primary" onClick={() => onExportClick(viewFilter, selectedBillingEntity, sortBy, apifilters)} className="mb-3">
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderViewFilter = (setViewValue) => {
    return (
      <>
        <ViewFilter views={viewFilterValues} defaultView={"deploying"} setSelectedValue={setViewValue} />
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return (
      <>
        <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="Devis"></HeaderMenu>
      <ContainerWrapper>
        <EntityList getColumns={initColumns} getEntities={API.getQuotes} headerActions={renderHeaderAction} viewFiltersRenderer={renderViewFilter} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default QuoteList;
