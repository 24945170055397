import React from "react";

export const ContainerHeaderBar = ({ leftContentClassName, leftContent, rightContentClassName, rightContent }) => {
  return (
    <>
      <div className="header-bar">
        <div className={leftContentClassName}>{leftContent}</div>
        <div className={`header-action ${rightContentClassName}`}>{rightContent}</div>
      </div>
      <br />
    </>
  );
};

ContainerHeaderBar.defaultProps = {
  leftContentClassName: "",
  rightContentClassName: "",
};

export default ContainerHeaderBar;
