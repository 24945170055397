import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { HeaderMenu } from "../../navigation/HeaderMenu";

import { API } from "../../../utils/API";
import { DateUtils } from "../../../utils/DateUtils";
import { UrlUtils } from "../../../utils/UrlUtils";
import { DecimalUtils } from "../../../utils/DecimalUtils";
import { useUser } from "../../../utils/UserContext";

import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import ViewFilter from "../../shared/ScopeFilter/ViewFilter";
import ContainerWrapper from "../../shared/ContainerWrapper";
import EntityList from "../../shared/EntityList";

const BillList = () => {
  const user = useUser();

  const viewFilterValues = [
    { id: "payable", name: "Factures à payer / à ventiler" },
    { id: "paid", name: "Factures payées" },
    { id: "all", name: "Toutes" },
  ];

  const initColumns = (viewFilter) => {
    let columnsArray = [];

    columnsArray.push(
      {
        id: "Code",
        Header: "Ref",
        accessor: "code",
        className: "text-nowrap",
        Cell: ({ row }) => <Link to={UrlUtils.billDetail(row.original.id)}>{row.original.code}</Link>,
        disableFilters: true,
      },
      {
        id: "Chrono",
        Header: "No Chrono",
        accessor: "chrono",
        disableFilters: true,
      }
    );

    if (user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) {
      columnsArray.push({
        id: "BillingEntityName",
        Header: "Entité de facturation",
        accessor: "billingEntityName",
        disableFilters: true,
      });
    }

    columnsArray.push(
      {
        id: "Type",
        Header: "Type",
        accessor: "typeName",
        disableFilters: true,
      },
      {
        id: "Date",
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.date)}</span>,
        disableFilters: true,
      },
      {
        id: "Duedate",
        Header: "Echéance",
        accessor: "duedate",
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.duedate)}</span>,
        disableFilters: true,
      },
      {
        id: "BillStartPeriod",
        Header: "Période",
        accessor: "period",
        disableFilters: true,
      },
      {
        id: "Status",
        Header: "Statut",
        accessor: "status",
        disableFilters: true,
      },
      {
        id: "NetAmount",
        Header: "Montant HT",
        accessor: "netAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.netAmount)}</span>,
        disableFilters: true,
      },
      {
        id: "Taxes",
        Header: "TVA",
        accessor: "taxes",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.taxes)}</span>,
        disableFilters: true,
      },
      {
        id: "GrossAmount",
        Header: "Montant TTC",
        accessor: "grossAmount",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.grossAmount)}</span>,
        disableFilters: true,
      },
      {
        id: "Balance",
        Header: "Restant à régler / Restant à ventiler",
        accessor: "balance",
        className: "text-nowrap text-end",
        Cell: ({ row }) => <span>{DecimalUtils.displayPrice(row.original.balance)}</span>,
        disableSortBy: true,
        disableFilters: true,
      }
    );

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportBills(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderFooter = (columns, data) => {
    let footerData = {
      netAmount: data.totalNetAmount,
      taxes: data.totalTaxes,
      grossAmount: data.totalGrossAmount,
      balance: data.totalBalance,
    };
    return (
      <>
        {footerData && columns && columns.length > 1 && (
          <tfoot>
            <tr>
              <td>
                <span className="text-start fw-bold">Total</span>
              </td>
              <td></td>
              <td colSpan={columns.length - 6}></td>
              <td className="text-nowrap text-end fw-bold">
                <span>{DecimalUtils.displayPrice(footerData.netAmount)}</span>
              </td>
              <td className="text-nowrap text-end fw-bold">
                <span>{DecimalUtils.displayPrice(footerData.taxes)}</span>
              </td>
              <td className="text-nowrap text-end fw-bold">
                <span>{DecimalUtils.displayPrice(footerData.grossAmount)}</span>
              </td>
              <td className="text-nowrap text-end fw-bold">
                <span>{DecimalUtils.displayPrice(footerData.balance)}</span>
              </td>
            </tr>
          </tfoot>
        )}
      </>
    );
  };

  const renderHeaderAction = (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    return (
      <>
        <Button color="primary" onClick={() => onExportClick(viewFilter, selectedBillingEntity, sortBy, apifilters)} className="mb-3">
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderViewFilter = (setViewValue) => {
    return (
      <>
        <ViewFilter views={viewFilterValues} defaultView={"payable"} setSelectedValue={setViewValue} />
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return (
      <>
        <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="Factures"></HeaderMenu>
      <ContainerWrapper>
        <EntityList
          getColumns={initColumns}
          getEntities={API.getBills}
          headerActions={renderHeaderAction}
          viewFiltersRenderer={renderViewFilter}
          scopeFiltersRenderer={renderScopeFilter}
          footer={renderFooter}
        />
      </ContainerWrapper>
    </div>
  );
};

export default BillList;
