import { useEffect, useState } from "react";
import { Form } from "reactstrap";

import { getAPIFilter } from "../../utils/API";

import DataTable from "./DataTable";
import Error from "./Error";
import { Loading } from "./Loading";

import ContainerHeaderBar from "./ContainerHeaderBar";

const EntityList = ({ getColumns, getEntities, headerActions, viewFiltersRenderer, scopeFiltersRenderer, footer }) => {
  const [error, setError] = useState();

  const [columns, setColumns] = useState([]);
  const [columnsLoaded, setColumnsLoaded] = useState(false);
  const [viewFilter, setViewFilter] = useState();
  const [scopeFilters, setScopeFilters] = useState();

  const [entities, setEntities] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [fetchData, setFetchData] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [apifilters, setApiFilters] = useState([]);

  const [resetAll, setResetAll] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sortBy, setSortBy] = useState([]);
  const [filters, setFilters] = useState([]);

  const initColumns = (viewFilter) => {
    let columnsArray = getColumns(viewFilter);
    setColumns(columnsArray);
    setColumnsLoaded(true);
  };

  useEffect(() => {
    if (columnsLoaded) return;
    if (viewFilter || !viewFiltersRenderer) initColumns(viewFilter);
  }, [columnsLoaded, viewFilter]);

  const convertTableFiltersIntoApiFilters = (filters) => {
    var apiFilters = [];

    if (filters && filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i] && filters[i].id) {
          var filterType = columns.filter((o) => o.id === filters[i].id)[0].filterType;
          let filter = getAPIFilter(filterType, filters[i].id, filters[i].value);
          if (filter) apiFilters.push(filter);
        }
      }
    }
    return apiFilters;
  };

  const fetchEntities = async () => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    let convertedFilters = convertTableFiltersIntoApiFilters(filters);
    setApiFilters(convertedFilters);
    const request = getEntities(pageIndex, pageSize, sortedColumn, sortDirection, viewFilter, scopeFilters, convertedFilters);
    request
      .then((data) => {
        if (!request.aborted) {
          setError(null);
          setEntities(data);
          setDataLoading(false);
          setFetchData(false);
          setResetAll(false);
          setResetPage(false);
          setPageCount(Math.ceil(data.hitcount / pageSize));
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setError(error);
          setEntities(null);
          setDataLoading(false);
          setFetchData(false);
          setResetAll(false);
          setResetPage(false);
        }
      });
  };

  useEffect(() => {
    if (!columnsLoaded || !fetchData) return;
    fetchEntities();
  }, [columnsLoaded, fetchData]);

  const renderFooter = () => {
    if (footer) return footer(columns, entities);
    else return <></>;
  };

  const onViewFilterChanged = (viewValue) => {
    setViewFilter(viewValue);
    setColumnsLoaded(false);
    resetAllDatatableInput();
  };

  const onScopeFilterChanged = (scopeValue) => {
    setScopeFilters(scopeValue);
    resetAllDatatableInput();
  };

  const resetAllDatatableInput = () => {
    setPageIndex(0);
    setFilters([]);
    setSortBy([]);
    setResetAll(true);
    setFetchData(true);
  };

  const updatePage = (srcPageIndex, srcPageSize) => {
    if (pageIndex !== srcPageIndex || pageSize !== srcPageSize) {
      setPageIndex(srcPageIndex);
      setPageSize(srcPageSize);
      if (!resetAll) setFetchData(true);
    }
  };

  const updateFilters = (srcFilters) => {
    if (filters.length === 0 && srcFilters.length === 0) return;

    setFilters(srcFilters);
    if (pageIndex !== 0) setResetPage(true);
    else if (!resetAll) setFetchData(true);
  };

  const updateSortBy = (srcSortBy) => {
    if (sortBy.length === 0 && srcSortBy.length === 0) return;

    setSortBy(srcSortBy);
    if (pageIndex !== 0) setResetPage(true);
    else if (!resetAll) setFetchData(true);
  };

  return (
    <div>
      {error && <Error error={error} />}
      {!error && (
        <>
          <ContainerHeaderBar
            leftContentClassName={"col-md-6 form-inline"}
            leftContent={
              <Form>
                <div className="mb-3">{viewFiltersRenderer && viewFiltersRenderer(onViewFilterChanged)}</div>
                <div>{scopeFiltersRenderer && scopeFiltersRenderer(onScopeFilterChanged, setError)}</div>
              </Form>
            }
            rightContent={headerActions(viewFilter, scopeFilters, sortBy, apifilters)}
          />
          {dataLoading && <Loading></Loading>}
          {columnsLoaded && columns && columns.length > 1 && !dataLoading && entities && (
            <DataTable
              columns={columns}
              data={entities.results}
              pageSize={pageSize}
              pageCount={pageCount}
              footer={renderFooter}
              updatePage={updatePage}
              updateSortBy={updateSortBy}
              updateFilters={updateFilters}
              resetAll={resetAll}
              resetPage={resetPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EntityList;
