import React, { Fragment } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";

const DataTableClientSide = ({ columns, data, pageSize }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: pageSize,
      },
    },
    useSortBy,
    usePagination
  );

  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <Fragment key={index}>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                    },
                  ])}
                >
                  <span {...column.getHeaderProps([column.getSortByToggleProps()])}>
                    {column.canSort && (
                      <span className="customlink">
                        {column.render("Header")} {column.isSorted ? <i className={`bi ${column.isSortedDesc ? "bi-caret-down" : "bi-caret-up"}`}></i> : ""}
                      </span>
                    )}
                    {!column.canSort && <span>{column.render("Header")}</span>}
                  </span>
                </th>
              ))}
            </tr>
          </Fragment>
        ))}
      </thead>
    );
  };

  const renderRows = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {page && page.length > 0 && (
          <>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                          },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </>
        )}
        {(!page || page.length === 0) && (
          <tr>
            <td colSpan={columns.length}>Aucune donnée à afficher</td>
          </tr>
        )}
      </tbody>
    );
  };

  const renderPagination = () => {
    return (
      <>
        {pageCount > 1 && (
          <Pagination>
            <PaginationItem disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
              <PaginationLink first />
            </PaginationItem>

            <PaginationItem disabled={!canPreviousPage} onClick={() => previousPage()}>
              <PaginationLink previous />
            </PaginationItem>

            <PaginationItem active>
              <PaginationLink>
                {pageIndex + 1} / ({pageCount})
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={!canNextPage} onClick={() => nextPage(0)}>
              <PaginationLink next />
            </PaginationItem>

            <PaginationItem disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}>
              <PaginationLink last />
            </PaginationItem>
          </Pagination>
        )}
      </>
    );
  };

  return (
    <>
      <div className="table-responsive">
        <Table striped hover {...getTableProps()}>
          {renderHeader()}
          {renderRows()}
        </Table>
        {renderPagination()}
      </div>
    </>
  );
};

DataTableClientSide.defaultProps = {
  pageSize: 20,
};

export default DataTableClientSide;
