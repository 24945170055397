import { useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { useUser } from "../../../utils/UserContext";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import ContainerWrapper from "../../shared/ContainerWrapper";
import Error from "../../shared/Error";
import { scopes } from "../../../utils/AuthAzureConfig";
import { callMsGraph } from "../../../utils/graph";
import UserAccountDrive from "./UserAccountDrive";
import UserAccountServices from "./UserAccountServices";
import UserAccountAlias from "./UserAccountAlias";
import UserAccountLicenses from "./UserAccountLicenses";

const UserAccountDetailsO365 = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();
  const { instance, accounts } = useMsal();
  const [userGraphData, setUserGraphData] = useState(null);
  const [mailGraphData, setMailGraphData] = useState(null);
  const user = useUser();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setError({ message: `Invalid Id ${id}` });
      setLoading(false);
    } else {
      const request = API.getUserAccount(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  useEffect(() => {
    if (entity && entity.upn) {
      instance
        .acquireTokenSilent({
          scopes,
          account: accounts[0],
          loginHint: user.upn,
        })
        .then((response) => {
          callMsGraph(response.accessToken, `https://graph.microsoft.com/v1.0/users/${entity.upn}`).then((response) => setUserGraphData(response));
        });
    }
  }, [entity]);

  // useEffect(() => {
  //   if (userGraphData) {
  //     instance
  //       .acquireTokenSilent({
  //         scopes,
  //         account: accounts[0],
  //         loginHint: user.upn,
  //       })
  //       .then((response) => {
  //         callMsGraph(response.accessToken, `https://graph.microsoft.com/v1.0/users/${entity.upn}`).then((response) => setUserGraphData(response));
  //       });
  //   }
  // }, [userGraphData]);

  const renderForms = () => {
    return (
      <>
        <div className="row">
          <ReadOnlyTableCellWrapper>
            <ReadOnlyTableCell fieldTitle="Code" fieldValue={entity.code} bold={true} />
            <ReadOnlyTableCell fieldTitle="Nom" fieldValue={entity.lastName} />
            <ReadOnlyTableCell fieldTitle="Prénom" fieldValue={entity.firstName} />
            <ReadOnlyTableCell fieldTitle="Statut" fieldValue={entity.status} />
            <ReadOnlyTableCell fieldTitle="Upn" fieldValue={entity.upn} />
            <ReadOnlyTableCell fieldTitle="sip" fieldValue={entity.sip} />
            <ReadOnlyTableCell fieldTitle="SDA" fieldValue={entity.sda} />
            <ReadOnlyTableCell fieldTitle="Authentification" fieldValue={entity.authenficication} />
          </ReadOnlyTableCellWrapper>
        </div>
        {userGraphData && (
          <div className="border border-primary">
            <p>User data :</p>
            <pre>{JSON.stringify(userGraphData, null, 2)}</pre>
          </div>
        )}
        <UserAccountAlias instance={instance} account={accounts[0]} loginHint={user.upn} userUpn={entity.upn} />
        {userGraphData && <UserAccountDrive instance={instance} account={accounts[0]} userId={userGraphData.id} loginHint={user.upn} />}
        <UserAccountServices userId={id} />
        {userGraphData && <UserAccountLicenses instance={instance} account={accounts[0]} loginHint={user.upn} userId={userGraphData.id} />}
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return renderForms();
    }
  };

  return (
    <>
      <HeaderMenu title="Utilisateur"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

export default UserAccountDetailsO365;
