import { Button } from "reactstrap";
import { HeaderMenu } from "../../navigation/HeaderMenu";

import { API, FilterFieldTypes } from "../../../utils/API";
import { useUser } from "../../../utils/UserContext";

import ContainerWrapper from "../../shared/ContainerWrapper";
import CheckBoxColumnFilter from "../../shared/Filters/CheckBoxColumnFilter";
import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import EntityList from "../../shared/EntityList";

const SDAList = () => {
  const user = useUser();

  const statusFilterValues = [
    { id: 1, name: "Actif" },
    { id: 0, name: "Inactif" },
    { id: -1, name: "Résilié" },
  ];

  const operatorStatusFilterValues = [
    { id: 0, name: "A commander" },
    { id: 1, name: "Actif" },
    { id: 2, name: "A résilier" },
    { id: 3, name: "Résilié" },
    { id: 4, name: "Réattribué" },
    { id: 5, name: "Porté" },
  ];

  const initColumns = (viewValue) => {
    let columnsArray = [];

    columnsArray.push({
      id: "Phone",
      Header: "N° Tel",
      accessor: "phone",
      headerClassName: "text-nowrap",
      className: "text-nowrap",
      disableFilters: true,
    });

    if (user.hasAdminRole || user.hasEntityAdminRole) {
      columnsArray.push({
        id: "BillingEntityName",
        Header: "Entité de facturation",
        accessor: "billingEntityName",
        disableFilters: true,
      });
    }

    columnsArray.push(
      {
        id: "SiteName",
        Header: "Site",
        accessor: "siteName",
        disableFilters: true,
      },
      {
        id: "Status",
        Header: "Statut",
        accessor: "status",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.Set,
        filterValues: statusFilterValues,
        Filter: (column, togglefilter) => <CheckBoxColumnFilter column={column} togglefilter={togglefilter} />,
      },
      {
        id: "OperatorStatus",
        Header: "Statut Opérateur",
        accessor: "operatorStatus",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        filterType: FilterFieldTypes.Set,
        filterValues: operatorStatusFilterValues,
        Filter: (column, togglefilter) => <CheckBoxColumnFilter column={column} togglefilter={togglefilter} />,
      }
    );

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportSDAs(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderHeaderAction = (viewFilter, scopeFilters, sortBy, apifilters) => {
    return (
      <>
        <Button color="primary" onClick={() => onExportClick(viewFilter, scopeFilters, sortBy, apifilters)}>
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return (
      <>
        <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="SDAs"></HeaderMenu>
      <ContainerWrapper>
        <EntityList getColumns={initColumns} getEntities={API.getSDAs} headerActions={renderHeaderAction} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default SDAList;
