import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useUser } from "../../utils/UserContext";
import { Avatar } from "./Avatar";

export const UserStatus = () => {
  const [userStatusExpanded, setUserStatusExpanded] = useState(false);
  const user = useUser();
  const toggle = () => setUserStatusExpanded(!userStatusExpanded);
  const logout = () => (window.location.href = "/api/accounts/logout");

  if (!user) return "";

  return (
    <Dropdown className="user-select-none" isOpen={userStatusExpanded} toggle={toggle}>
      <DropdownToggle data-toggle="dropdown" tag="div" className=" dropdown-toggle cursor-pointer">
        <Avatar user={user} className="avatar rounded-circle" />
      </DropdownToggle>
      <DropdownMenu className="mt-2 pt-0 user-status-dropdown-menu">
        <a className="px-3 py-4 d-block no-decoration" href={"/me"}>
          <div className="text-center mb-2">
            <Avatar user={user} className="avatar rounded-circle" style={{ width: "40px" }} />
          </div>
          <div className="text-center">
            <div>
              {user.firstName} {user.lastName}
            </div>
            <div className="text-muted">{user.email}</div>
          </div>
        </a>
        <div className="dropdown-divider mt-0"></div>
        <div className="dropdown-item cursor-pointer" onClick={logout}>
          <i className="bi-power me-3"></i>
          <span className="text-danger">Déconnexion</span>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
