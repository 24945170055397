import { Link, useLocation } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export const NavDropDownLink = ({ title, items, textColor }) => {
  const location = useLocation();

  let isActive = items && items.length > 0 && items.some((o) => location.pathname.startsWith(o.to));
  let className = "text-" + (textColor ?? "dark");
  let toggleClassName = className;
  if (isActive) toggleClassName = " active";

  return (
    <>
      {items && items.length > 0 && (
        <UncontrolledDropdown inNavbar nav>
          <DropdownToggle caret nav className={toggleClassName}>
            {title}
          </DropdownToggle>
          <DropdownMenu>
            {items.map((item, index) => (
              <DropdownItem tag={Link} key={index} className={className} to={item.to}>
                {item.title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

export default NavDropDownLink;
