import { graphConfig } from "./AuthAzureConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken, query) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(query ?? graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}
