import React from "react";
import { UrlUtils } from "../../../utils/UrlUtils";
import { DateUtils } from "../../../utils/DateUtils";
import { Link } from "react-router-dom";

const TicketConversation = ({ id, conversations }) => {
  const renderEmail = (conversation) => {
    return (
      <>
        <span>{DateUtils.formatDateTime(conversation.ctime)}</span>
        <span className="ms-2">
          <b>Sujet :</b> {conversation.subject}
        </span>
        <br />
        <b>De :</b> {conversation.from}
        <Link className="ms-2" to={UrlUtils.ticketEmail(id, conversation.id)} target="_blank">
          Voir le contenu de l'Email
        </Link>
      </>
    );
  };

  const renderNote = (conversation) => {
    return (
      <>
        <span>{DateUtils.formatDateTime(conversation.ctime)}</span>
        <br />
        <b>De :</b> {conversation.from}
        <br />
        <div dangerouslySetInnerHTML={{ __html: conversation.body }}></div>
      </>
    );
  };

  return (
    <div>
      {conversations.map((value, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            {value.isEmail && renderEmail(value)}
            {!value.isEmail && renderNote(value)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TicketConversation;
