import { useState, useEffect } from "react";

import { scopes } from "../../../utils/AuthAzureConfig";
import { callMsGraph } from "../../../utils/graph";
import { Loading } from "../../shared/Loading";
import DataTableClientSide from "../../shared/DataTableClientSide";

const UserAccountAlias = ({ instance, account, loginHint, userUpn }) => {
  const [aliasGraphData, setAliasGraphData] = useState(null);
  const [proxyAddresses, setProxyAddresses] = useState();
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
  ];

  useEffect(() => {
    if (userUpn) {
      instance
        .acquireTokenSilent({
          scopes,
          account: account,
          loginHint: loginHint,
        })
        .then((response) => {
          callMsGraph(response.accessToken, `https://graph.microsoft.com/v1.0/users/${userUpn}?$select=proxyaddresses`).then((response) => setAliasGraphData(response));
        });
    }
  }, []);

  useEffect(() => {
    /*
    For example: ["SMTP: bob@contoso.com", "smtp: bob@sales.contoso.com"]. Changes to the mail property will also update this collection to include the value as an SMTP address.
    For more information, see mail and proxyAddresses properties. The proxy address prefixed with SMTP (capitalized) is the primary proxy address while those prefixed with smtp are the secondary proxy addresses.
    For Azure AD B2C accounts, this property has a limit of ten unique addresses. Read-only in Microsoft Graph; you can update this property only through the Microsoft 365 admin center. Not nullable.
    https://learn.microsoft.com/en-us/graph/api/resources/user?view=graph-rest-1.0
    */
    if (aliasGraphData && aliasGraphData.proxyAddresses && aliasGraphData.proxyAddresses.length > 0) {
      var aliases = [];
      for (var i = 0; i < aliasGraphData.proxyAddresses.length; ++i) {
        if (aliasGraphData.proxyAddresses[i].startsWith("smtp:") && aliasGraphData.proxyAddresses[i].length > 5) {
          var alias = aliasGraphData.proxyAddresses[i].substring(5);
          aliases.push({ email: alias });
        }
      }
      if (aliases.length > 0) setProxyAddresses(aliases);
    }
    setLoading(false);
  }, [aliasGraphData]);

  const renderContent = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (proxyAddresses) {
      return (
        <>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Liste des Alias</h5>
              <DataTableClientSide columns={columns} data={proxyAddresses} />
            </div>
          </div>
        </>
      );
    }
  };

  return <>{renderContent()}</>;
};

export default UserAccountAlias;
