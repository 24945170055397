import React from "react";
import { Table } from "reactstrap";
import { useTable } from "react-table";

const BillRelatedItem = ({ columns, data, title }) => {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const renderRows = () => {
    if (!data || data.length === 0)
      return (
        <tbody>
          <tr>
            <td colSpan={columns.length}>Aucune donnée à afficher</td>
          </tr>
        </tbody>
      );
    return (
      <>
        <thead>
          {headerGroups.map((group) => (
            <tr {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                    },
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td
                  {...column.getFooterProps([
                    {
                      className: column.footerClassName,
                    },
                  ])}
                >
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </>
    );
  };

  return (
    <>
      <Table striped hover className="caption-top" {...getTableProps()}>
        {title && <caption className="text-dark">{title}</caption>}
        {renderRows()}
      </Table>
    </>
  );
};

export default BillRelatedItem;
