import React, { useState } from "react";
import { API } from "../../../utils/API";
import Error from "../../shared/Error";
import InputWrapper from "../../shared/Forms/InputWrapper";
import FileInput from "../../shared/Forms/FileInput";
import { Button } from "reactstrap";
import { Formik, Form } from "formik";

const TicketImportFile = ({ id }) => {
  const [error, setError] = useState();

  const submitTicketImportFile = (formData) => {
    const request = API.updateTicketImportFile(id, formData);
    request
      .then((data) => {
        refreshCurrentPage();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const refreshCurrentPage = () => {
    window.location.reload(false);
  };

  return (
    <div className="area-border">
      {error && <Error error={error} />}
      {
        <Formik
          initialValues={{
            fileUpload: null,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append("FileUpload", values.fileUpload);
            submitTicketImportFile(formData);
            setSubmitting(false);
          }}
        >
          {(props) => (
            <Form>
              <InputWrapper>
                <FileInput label="Ajouter une pièce jointe" name="fileUpload" />
              </InputWrapper>
              <div className="mt-3">
                <Button color="success" type="submit">
                  Ajouter
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </div>
  );
};

export default TicketImportFile;
