import { Input, Label, FormFeedback } from "reactstrap";
import { useField } from "formik";

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Label className="fw-bold" htmlFor={props.id || props.name}>
        {label}
      </Label>
      <Input type="select" invalid={meta.touched && meta.error ? true : false} {...field} {...props} />
      {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
    </>
  );
};

export default SelectInput;
