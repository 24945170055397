import { useEffect, useState } from "react";
import React from "react";
import { useParams, Link } from "react-router-dom";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import { Table, Button } from "reactstrap";
import ContainerWrapper from "../../shared/ContainerWrapper";
import ContainerHeaderBar from "../../shared/ContainerHeaderBar";
import DecimalUtils from "../../../utils/DecimalUtils";
import { UrlUtils } from "../../../utils/UrlUtils";
import Error from "../../shared/Error";
import QuoteServiceDetail from "./QuoteServiceDetail";

const QuoteDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setLoading(false);
      setError({ message: `Invalid Id ${id}` });
    } else {
      const request = API.getQuote(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  const onPrintClick = async () => {
    const parsedId = parseInt(id, 10);
    var request = API.printQuote(parsedId);
    request.catch((error) => {
      if (!request.aborted) setError(error);
    });
  };

  const renderForms = () => {
    return (
      <>
        <ContainerHeaderBar
          rightContent={
            <>
              <Button color="success" className="mb-3" onClick={() => onPrintClick()}>
                <i className="bi bi-printer me-1"></i>
                <span>Imprimer</span>
              </Button>
              <Link to={UrlUtils.quotes()}>
                <Button color="primary" className="ms-1 mb-3">
                  <span>Liste Devis</span>
                </Button>
              </Link>
            </>
          }
        />

        <ReadOnlyTableCellWrapper>
          <ReadOnlyTableCell fieldTitle="Code" fieldValue={entity.code} fieldType="string" bold={true} />
          <ReadOnlyTableCell fieldTitle="Objet" fieldValue={entity.object} />
          <ReadOnlyTableCell fieldTitle="Statut" fieldValue={entity.status} />
          <ReadOnlyTableCell fieldTitle="Paiement" fieldValue={entity.paymentStatus} />
          <ReadOnlyTableCell fieldTitle="Facturation" fieldValue={entity.billFrequency} />
          <ReadOnlyTableCell fieldTitle="Cond. de paiement" fieldValue={entity.paymentTerm} />
          <ReadOnlyTableCell fieldTitle="Prelev. auto" fieldValue={entity.automaticBilling} fieldType="bool" />
          <ReadOnlyTableCell fieldTitle="Acompte" fieldValue={entity.depositAmount} fieldType="price" />
          <ReadOnlyTableCell fieldTitle="Date de signature" fieldValue={entity.dateSigned} fieldType="date" />
          <ReadOnlyTableCell fieldTitle="Date de déploiement" fieldValue={entity.dateDeployed} fieldType="date" />
        </ReadOnlyTableCellWrapper>

        <br />

        <Table striped>
          <thead>
            <tr>
              <th className="fw-bold" colSpan={2}>
                Total Non Récurrent
              </th>
              <th className="text-end fw-bold">Montant HT</th>
              <th className="text-end fw-bold">{`TVA ${entity.taxRate} %`}</th>
              <th className="text-end fw-bold">Montant TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}></td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.oneOffNetAmount)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.oneOffTaxes)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.oneOffGrossAmount)}</td>
            </tr>
            <tr>
              <td colSpan={5}>
                <br />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th className="fw-bold" colSpan={2}>
                Total Récurrent Mensuel
              </th>
              <th className="text-end fw-bold">Montant HT</th>
              <th className="text-end fw-bold">{`TVA ${entity.taxRate} %`}</th>
              <th className="text-end fw-bold">Montant TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}></td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.monthlyNetAmount)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.monthlyTaxes)}</td>
              <td className="text-end">{DecimalUtils.displayPrice(entity.monthlyGrossAmount)}</td>
            </tr>
          </tbody>
        </Table>

        <br />

        <Table striped>
          {entity.details.map((serviceDetail) => (
            <React.Fragment key={serviceDetail.id}>
              <QuoteServiceDetail serviceDetail={serviceDetail} taxRate={entity.taxRate} />
            </React.Fragment>
          ))}
        </Table>
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return <div className="table-responsive">{renderForms()}</div>;
    }
  };

  return (
    <>
      <HeaderMenu title="Détail de devis"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

export default QuoteDetails;
