import React from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from "reactstrap";
import { useUser } from "../../utils/UserContext";
import { useState } from "react";
import { UserStatus } from "../shared/UserStatus";
import NavMenuLink from "./NavMenuLink";
import NavDropDownLink from "./NavDropDownLink";

import "./NavMenu.scss";

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const user = useUser();

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header>
      <Navbar container={false} expand="lg" className="navmenu border-bottom box-shadow mb-3 py-2">
        <div className="container align-items-end">
          <NavbarBrand href="/">
            <img src={process.env.PUBLIC_URL + '/img/logo_xelya_horizontal.svg'} alt="Xelya" className="logo" />
          </NavbarBrand>
          <NavbarToggler aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
          <Collapse id="basic-navbar-nav" isOpen={!collapsed} navbar>
            <Nav className="me-auto text-right ms-5 nav-pills">
              <NavItem>
                <NavMenuLink to="/news">News</NavMenuLink>
              </NavItem>
              <NavItem>
                <NavMenuLink to="/tickets">Service client</NavMenuLink>
              </NavItem>
              {/* <NavItem>
                <NavMenuLink to="/contacts">Contacts</NavMenuLink>
              </NavItem> */}
              {(user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) && (
                <NavDropDownLink
                  title="Facturation"
                  items={[
                    { title: "Abonnements", to: "/services" },
                    { title: "Devis", to: "/quotes" },
                    { title: "Factures", to: "/bills" },
                    { title: "Inventaire", to: "/devices" },
                  ]}
                />
              )}
              {(user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) && (
                <NavDropDownLink
                  title="Consommations"
                  items={[
                    { title: "Téléphone", to: "/phonerecords" },
                    { title: "SDAs", to: "/sdas" },
                  ]}
                />
              )}
              {/* {(user.hasAdminRole || user.hasEntityAdminRole) && <NavDropDownLink title="Données O365" items={[{ title: "Utilisateurs", to: "/useraccounts" }]} />} */}
              <NavItem>
                <NavMenuLink to="/legalnotices">Infos légales</NavMenuLink>
              </NavItem>
              {(user.hasColibriRole || user.isImpersonating) && (
                <NavItem>
                  <NavMenuLink to="/impersonate">Agir sur</NavMenuLink>
                </NavItem>
              )}
            </Nav>
            <div className={`d-flex ${user.isImpersonating ? "background-impersonate" : ""}`}>
              <UserStatus />
            </div>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
};
