import { useState, useEffect } from "react";

import { Progress } from "reactstrap";

import { scopes } from "../../../utils/AuthAzureConfig";
import { callMsGraph } from "../../../utils/graph";
import DecimalUtils from "../../../utils/DecimalUtils";
import ByteUtils from "../../../utils/ByteUtils";

const UserAccountDrive = ({ instance, account, loginHint, userId }) => {
  const [driveGraphData, setDriveGraphData] = useState(null);
  const [percentUsed, setPercentUsed] = useState(-1);

  useEffect(() => {
    if (userId) {
      instance
        .acquireTokenSilent({
          scopes,
          account: account,
          loginHint: loginHint,
        })
        .then((response) => {
          callMsGraph(response.accessToken, `https://graph.microsoft.com/v1.0/users/${userId}/drive`).then((response) => setDriveGraphData(response));
        });
    }
  }, []);

  useEffect(() => {
    if (driveGraphData && driveGraphData.quota && driveGraphData.quota.total > 0) {
      var value = (driveGraphData.quota.used / driveGraphData.quota.total) * 100;
      setPercentUsed(DecimalUtils.round(value, 2));
    }
  }, [driveGraphData]);

  return (
    <>
      {percentUsed >= 0 && (
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">Stockage OneDrive</h5>
            <div className="text-center">{percentUsed}%</div>
            <Progress value={percentUsed} />
            <span>
              {ByteUtils.format(driveGraphData.quota.remaining)} libre sur {ByteUtils.format(driveGraphData.quota.total)}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserAccountDrive;
