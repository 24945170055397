import { useLocation } from "react-router-dom";

export const Error = ({ error }) => {
  const location = useLocation();

  const renderStandardErrorMessage = (message) => {
    return (
      <h4 className="py-5 text-center text-danger">
        <i className="bi bi-exclamation-circle pe-3"></i>
        {message}
      </h4>
    );
  };

  const renderError = (error) => {
    return (
      <div className="alert alert-danger" role="alert">
        <div className="d-flex">
          <div>
            <i className="bi bi-exclamation-circle pe-3"></i>
          </div>
          <div>
            <strong>{error.title}</strong>
            {error.detail && <div>{error.detail}</div>}
          </div>
        </div>
      </div>
    );
  };

  if (error) console.error(error);

  if (!error) {
    return null;
  } else if (error.error && error.error.title) {
    return renderError(error.error);
  } else if (error && error.status) {
    switch (error.status) {
      case 401:
        window.location.href = "/api/accounts/login?redirect=" + encodeURIComponent(location.pathname);
        return null;
      case 403:
        return renderStandardErrorMessage("Accès à la ressource interdit");
      case 404:
        return renderStandardErrorMessage("Ressource introuvable");
      default:
        return renderStandardErrorMessage("Une erreur inatendue est survenue (" + error.status + ")");
    }
  }
};

export default Error;
