export const DecimalUtils = {
  round: (value, decimals = 2) => {
    if (decimals) {
      var roundedValue = Math.round(((value ? value : 0) + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
      return roundedValue;
    } else {
      return 0;
    }
  },
  displayNumber: (value, decimals = 2, fixed = false) => {
    var roundedValue = DecimalUtils.round(value, decimals);
    return fixed
      ? roundedValue.toLocaleString("fr-FR", {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
      : roundedValue.toLocaleString("fr-FR");
  },
  displayPrice: (value, decimals = 2) => {
    return `${DecimalUtils.displayNumber(value, decimals, true)} €`;
  },
};

export default DecimalUtils;
