import { useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";

import { useUser } from "../../../utils/UserContext";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { Loading } from "../../shared/Loading";
import { API } from "../../../utils/API";
import ReadOnlyTableCellWrapper from "../../shared/ReadOnlyTableCellWrapper";
import ReadOnlyTableCell from "../../shared/ReadOnlyTableCell";
import ContainerWrapper from "../../shared/ContainerWrapper";
import Error from "../../shared/Error";

const UserAccountDetails = (me) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [error, setError] = useState();
  const user = useUser();

  useEffect(() => {
    const parsedId = me ? user.id : parseInt(id, 10);
    if (isNaN(parsedId) || parsedId <= 0) {
      setError({ message: `Invalid Id ${id}` });
      setLoading(false);
    } else {
      const request = API.getUserAccount(parsedId);
      request
        .then((data) => {
          if (!request.aborted) {
            setEntity(data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setEntity(null);
            setError(error);
            setLoading(false);
          }
        });

      return request.abort;
    }
  }, [id, setLoading]);

  const renderForms = () => {
    return (
      <>
        <div className="row">
          <ReadOnlyTableCellWrapper>
            <ReadOnlyTableCell fieldTitle="Code" fieldValue={entity.code} bold={true} />
            <ReadOnlyTableCell fieldTitle="Nom" fieldValue={entity.lastName} />
            <ReadOnlyTableCell fieldTitle="Prénom" fieldValue={entity.firstName} />
            <ReadOnlyTableCell fieldTitle="Statut" fieldValue={entity.status} />
            <ReadOnlyTableCell fieldTitle="Upn" fieldValue={entity.upn} />
            <ReadOnlyTableCell fieldTitle="sip" fieldValue={entity.sip} />
            <ReadOnlyTableCell fieldTitle="SDA" fieldValue={entity.sda} />
            <ReadOnlyTableCell fieldTitle="Authentification" fieldValue={entity.authenficication} />
          </ReadOnlyTableCellWrapper>
        </div>
      </>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (entity) {
      return renderForms();
    }
  };

  return (
    <>
      <HeaderMenu title="Utilisateur"></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </>
  );
};

UserAccountDetails.defaultProps = {
  me: false,
};

export default UserAccountDetails;
