import { Button } from "reactstrap";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { API } from "../../../utils/API";
import { useUser } from "../../../utils/UserContext";
import ContainerWrapper from "../../shared/ContainerWrapper";
import BillingEntityScopeFilter from "../../shared/ScopeFilter/BillingEntityScopeFilter";
import ViewFilter from "../../shared/ScopeFilter/ViewFilter";
import EntityList from "../../shared/EntityList";
import { DateUtils } from "../../../utils/DateUtils";

const DeviceList = () => {
  const user = useUser();

  const viewFilterValues = [
    { id: "pc", name: "PC" },
    { id: "others", name: "Autre" },
  ];

  const initColumns = (viewValue) => {
    let columnsArray = [];

    columnsArray.push({
      id: "Name",
      Header: "Nom",
      accessor: "name",
      headerClassName: "text-nowrap",
      className: "text-nowrap",
      // Cell: ({ row }) => <Link to={UrlUtils.ticketDetail(row.original.id)}>{row.original.code}</Link>,
      disableFilters: true,
    });

    if (user.hasAdminRole || user.hasEntityAdminRole || user.hasBillingRole) {
      columnsArray.push({
        id: "BillingEntityName",
        Header: "Entité de facturation",
        accessor: "billingEntityName",
        disableFilters: true,
      });
    }

    columnsArray.push(
      {
        id: "SiteName",
        Header: "Site",
        accessor: "siteName",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "Status",
        Header: "Statut",
        accessor: "status",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "Type",
        Header: "Type",
        accessor: "type",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
      },
      {
        id: "Brand",
        Header: "Marque",
        accessor: "brand",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: "Model",
        Header: "Modèle",
        accessor: "model",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: "Code",
        Header: "N° de série",
        accessor: "code",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: "WarrantyExpirationDate",
        Header: "Fin de garantie",
        accessor: "warrantyExpirationDate",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.warrantyExpirationDate)}</span>,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: "Ip",
        Header: "IP",
        accessor: "ip",
        headerClassName: "text-nowrap",
        className: "text-nowrap",
        disableFilters: true,
        disableSortBy: true,
      }
    );

    if (viewValue === "pc") {
      columnsArray.push(
        {
          id: "CurrentUser",
          Header: "Utilisateur",
          accessor: "currentUser",
          headerClassName: "text-nowrap",
          className: "text-nowrap",
          disableFilters: true,
          disableSortBy: true,
        },
        {
          id: "InventoryDate",
          Header: "Date inventaire",
          accessor: "inventoryDate",
          headerClassName: "text-nowrap",
          className: "text-nowrap",
          Cell: ({ row }) => <span>{DateUtils.formatDate(row.original.inventoryDate)}</span>,
          disableFilters: true,
          disableSortBy: true,
        }
      );
    }

    return columnsArray;
  };

  const onExportClick = async (viewFilter, selectedBillingEntity, sortBy, apifilters) => {
    let sortedColumn;
    let sortDirection;
    if (sortBy && sortBy.length) {
      sortedColumn = sortBy[0].id;
      sortDirection = sortBy[0].desc;
    }

    API.exportDevices(sortedColumn, sortDirection, viewFilter, selectedBillingEntity, apifilters);
  };

  const renderHeaderAction = (viewFilter, scopeFilters, sortBy, apifilters) => {
    return (
      <>
        <Button color="primary" onClick={() => onExportClick(viewFilter, scopeFilters, sortBy, apifilters)}>
          <i className="bi bi-arrow-down-circle me-1"></i>
          <span>Export</span>
        </Button>
      </>
    );
  };

  const renderViewFilter = (setViewValue) => {
    return (
      <>
        <ViewFilter views={viewFilterValues} defaultView={"pc"} setSelectedValue={setViewValue} />
      </>
    );
  };

  const renderScopeFilter = (setScopeFilters, setError) => {
    return (
      <>
        <BillingEntityScopeFilter setSelectedValue={setScopeFilters} setError={setError} />
      </>
    );
  };

  return (
    <div>
      <HeaderMenu title="Inventaire"></HeaderMenu>
      <ContainerWrapper>
        <EntityList getColumns={initColumns} getEntities={API.getDevices} headerActions={renderHeaderAction} viewFiltersRenderer={renderViewFilter} scopeFiltersRenderer={renderScopeFilter} />
      </ContainerWrapper>
    </div>
  );
};

export default DeviceList;
