import React from "react";
import { HeaderMenu } from "../navigation/HeaderMenu";
import ContainerWrapper from "../shared/ContainerWrapper";
import { useEffect, useState } from "react";
import { Loading } from "../shared/Loading";
import { DateUtils } from "../../utils/DateUtils";
import Error from "../shared/Error";

const SiteAssets = ({ title, getSiteAssets }) => {
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const request = getSiteAssets();
    request
      .then((data) => {
        if (!request.aborted) {
          setAssets(data);
          setError(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!request.aborted) {
          setAssets(null);
          setError(error);
          setLoading(false);
        }
      });

    return request.abort;
  }, [getSiteAssets]);

  const renderSeparator = (index) => {
    if (index) {
      return (
        <tr>
          <td colSpan="2">
            <div className="list-separator"></div>
          </td>
        </tr>
      );
    } else {
      return null;
    }
  };

  const renderAssets = () => {
    return (
      <table aria-labelledby="tabelLabel">
        <tbody>
          {assets.map((article, index) => (
            <React.Fragment key={index}>
              {renderSeparator(index)}
              <tr>
                <td width="120px" valign="top">
                  <div className="item-date" id="date">
                    {DateUtils.customFormat(article.date, "D MMMM")}
                    <br />
                    {DateUtils.customFormat(article.date, "YYYY")}
                  </div>
                </td>
                <td valign="top">
                  <div className="item-title">{article.title}</div>
                  <div className="item-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <Loading></Loading>;
    } else if (assets) {
      return renderAssets();
    }
  };

  return (
    <div>
      <HeaderMenu title={title}></HeaderMenu>
      <ContainerWrapper>
        {error && <Error error={error} />}
        {!error && renderContents()}
      </ContainerWrapper>
    </div>
  );
};

export default SiteAssets;
