import { API } from "../../../utils/API";
import TypeAheadScopeFilter from "./TypeAheadScopeFilter";

export const BillingEntityScopeFilter = ({ setSelectedValue, setError }) => {
  return (
    <>
          <TypeAheadScopeFilter setSelectedValue={setSelectedValue} getValues={API.getUserBillingEntities} placeHolder="Filtrer par entité de facturation..." pageSize={200} containsSearch={true} />
    </>
  );
};

export default BillingEntityScopeFilter;
