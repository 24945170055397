import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { HeaderMenu } from "../../navigation/HeaderMenu";
import { API } from "../../../utils/API";
import ContainerWrapper from "../../shared/ContainerWrapper";
import ContainerHeaderBar from "../../shared/ContainerHeaderBar";
import Error from "../../shared/Error";
import InputWrapper from "../../shared/Forms/InputWrapper";
import SelectInput from "../../shared/Forms/SelectInput";
import TextInput from "../../shared/Forms/TextInput";
import TextAreaInput from "../../shared/Forms/TextAreaInput";
import FileInput from "../../shared/Forms/FileInput";
import { Loading } from "../../shared/Loading";
import { Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { UrlUtils } from "../../../utils/UrlUtils";

const NewTicket = () => {
  const [error, setError] = useState();
  const [backToList, setBackToList] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesLoaded, setservicesLoaded] = useState(false);
  const [selectedService, setSelectedService] = useState(0);
  const [serviceDomains, setServiceDomains] = useState([]);
  const [serviceDomainsLoaded, setServiceDomainsLoaded] = useState(true);

  useEffect(() => {
    if (!servicesLoaded) {
      const request = API.getIsoServices();
      request
        .then((data) => {
          if (!request.aborted) {
            setServices(data);
            setservicesLoaded(true);
            setError(null);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            setServices([]);
            setservicesLoaded(true);
            setError(error);
          }
        });
    }
  }, [servicesLoaded]);

  useEffect(() => {
    if (!serviceDomainsLoaded && selectedService && selectedService !== 0) {
      const request = API.getIsoServiceDomains(selectedService);
      request
        .then((data) => {
          if (!request.aborted) {
            setServiceDomains(data);
            setServiceDomainsLoaded(true);
            setError(null);
          }
        })
        .catch((error) => {
          if (!request.aborted) {
            serviceDomains([]);
            setServiceDomainsLoaded(true);
            setError(error);
          }
        });
    }
  }, [serviceDomainsLoaded, selectedService]);

  const onServiceChange = (selectedValue, formikHandlers) => {
    setSelectedService(selectedValue);
    if (selectedValue > 0) setServiceDomainsLoaded(false);
    formikHandlers.setFieldValue("serviceId", selectedValue);
    // formikHandlers.setFieldTouched("serviceId", true);
    formikHandlers.setFieldValue("serviceDomainId", 0);
  };

  const submitCreateTicket = (formData) => {
    const request = API.createTicket(formData);
    request
      .then((data) => {
        goBackToList();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const goBackToList = () => {
    setBackToList(true);
  };

  if (backToList) {
    return <Redirect to={UrlUtils.tickets()} />;
  } else {
    return (
      <div>
        <HeaderMenu title="Nouvelle demande"></HeaderMenu>
        <ContainerWrapper>
          {error && <Error error={error} />}

          <ContainerHeaderBar
            rightContent={
              <>
                <Link to={UrlUtils.tickets()}>
                  <Button color="danger" className="mb-3">
                    <span>Annuler</span>
                  </Button>
                </Link>
              </>
            }
          />

          {servicesLoaded && (
            <Formik
              initialValues={{
                subject: "",
                description: "",
                serviceId: 0,
                serviceDomainId: 0,
                fileUpload: null,
              }}
              validationSchema={Yup.object({
                subject: Yup.string().required("Requis").max(255, "Le sujet ne peut avoir plus de 255 caractères"),
                description: Yup.string().required("Requis"),
                serviceId: Yup.number().integer().moreThan(0, "Vous devez sélectioner un service"),
                  serviceDomainId: Yup.number().integer()
                      .test("Required", "Vous devez sélectioner un domaine", (val) => val > 0 || serviceDomains.length === 0),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const formData = new FormData();
                formData.append("Subject", values.subject);
                formData.append("Description", values.description);
                formData.append("ServiceId", values.serviceId);
                formData.append("ServiceDomainId", values.serviceDomainId);
                formData.append("FileUpload", values.fileUpload);
                submitCreateTicket(formData);
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form>
                  <InputWrapper>
                    <SelectInput label="Service" name="serviceId" onChange={(e) => onServiceChange(e.target.value, props)}>
                      <option key={0} value={0}>
                        {"Sélectionner le service..."}
                      </option>
                      {services.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </SelectInput>
                  </InputWrapper>
                   <InputWrapper>
                    {serviceDomainsLoaded && (
                      <SelectInput label="Domaine" name="serviceDomainId" disabled={selectedService === 0 || serviceDomains.length === 0}>
                        <option key={0} value={0}>
                          {"Sélectionner le domaine..."}
                        </option>
                        {serviceDomains.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </SelectInput>
                    )}
                    {!serviceDomainsLoaded && <Loading></Loading>}
                  </InputWrapper> 
                  <InputWrapper>
                    <TextInput label="Sujet" name="subject" />
                  </InputWrapper>
                  <InputWrapper>
                    <TextAreaInput label="Description" name="description" rows={14} />
                  </InputWrapper>
                  <InputWrapper>
                    <FileInput label="Ajouter une pièce jointe" name="fileUpload" />
                  </InputWrapper>
                  <div className="mt-3">
                    <Button color="success" type="submit">
                      Créer
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </ContainerWrapper>
      </div>
    );
  }
};

export default NewTicket;
