import { Input, Label, Table } from "reactstrap";
import { useField, Field } from "formik";
import React from "react";

const CheckboxInput = ({ field, form, ...rest }) => {
  const { name, value: formikValue } = field;
  const { setFieldValue, setFieldTouched } = form;

  const handleChange = (event) => {
    const values = formikValue || [];
    const index = values.indexOf(rest.value);
    if (index === -1) {
      values.push(rest.value);
    } else {
      values.splice(index, 1);
    }
    setFieldValue(name, values);
    setFieldTouched(name, true);
  };

  return (
    <label>
      <Input type="checkbox" onChange={handleChange} checked={formikValue.indexOf(rest.value) !== -1} {...rest} />
    </label>
  );
};

const CheckBoxList = ({ label, values, name, ...props }) => {
  const [, meta] = useField(name);

  return (
    <>
      <Label className="fw-bold">{label}</Label>
      <div className="table-responsive">
        <Table striped>
          <tbody>
            {values.map((option) => (
              <React.Fragment key={option.id}>
                <tr>
                  <td width="1em">
                    <Field component={CheckboxInput} name={name} value={option.id} />
                  </td>
                  <td align="left">
                    <Label for={`${props.name}-${option.id}`}>{option.name}</Label>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        {meta.touched && meta.error ? <div className="d-block invalid-feedback">{meta.error}</div> : null}
      </div>
    </>
  );
};

export default CheckBoxList;
