import moment from "moment";
import "moment/locale/fr";

export const DateUtils = {
  customFormat: (date, format) => {
    return date ? moment(date).format(format) : "";
  },
  formatDate: (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "";
  },
  formatDateTime: (date) => {
    return date ? moment(date).format("DD/MM/YYYY kk:mm:ss") : "";
  },
  convertDateFromIso: (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  },
  convertDateToIso: (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DDT00:00:00");
    } else {
      return "";
    }
  },
};
