import { useState } from "react";
import { Input, InputGroup, Button } from "reactstrap";

export const ImpersonateScopeFilter = ({ setSelectedValue, setError }) => {
  const [userAccountName, setUserAccountName] = useState();
  const [clientCode, setClientCode] = useState();

  const updateFilters = () => {
    setSelectedValue({ userAccountName: userAccountName, clientCode: clientCode });
  };

  return (
    <>
      <InputGroup>
        <Input type="text" className="text-input" placeholder="Utilisateur" onChange={(event) => setUserAccountName(event.target.value)}></Input>
        <Input type="text" className="text-input" placeholder="Client" onChange={(event) => setClientCode(event.target.value)}></Input>
        <Button color="primary" onClick={() => updateFilters()}>
          <i className="bi bi-search me-1"></i>
          <span>Filtrer</span>
        </Button>
      </InputGroup>
    </>
  );
};

export default ImpersonateScopeFilter;
