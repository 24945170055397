import { Label } from "reactstrap";
import { useField } from "formik";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { useState } from "react";

export const RateStarInput = ({ label, name, defaultValue, size, count, displayValues, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const [onHoverValue, SetOnHoverValue] = useState(null);
  const [selectedValue, SetSelectedValue] = useState(defaultValue);

  const setValue = (helpers, value) => {
    helpers.setValue(value);
    SetSelectedValue(value);
    // quick fix to trigger validation, otherwise validation is done on next compute
    setTimeout(() => helpers.setTouched(true));
  };

  const OnStarHover = (value) => {
    SetOnHoverValue(value);
  };

  const OnRatingMouseOut = () => {
    SetOnHoverValue(null);
  };

  return (
    <>
      <div className="d-flex flex-row">
        <Label className="fw-bold" htmlFor={props.id || props.name}>
          {label}
        </Label>
        <div className="ms-1 mandatory" title="Champ obligatoire"></div>
      </div>
      <div className="d-flex flex-row">
        <div onMouseLeave={() => OnRatingMouseOut()}>
          <Rate defaultValue={defaultValue} count={count} onChange={(o) => setValue(helpers, o)} onHoverChange={(o) => OnStarHover(o)} style={{ fontSize: size }} allowClear={false} />
        </div>
        <div className="ms-4 fw-bold d-flex align-items-center">
          {displayValues && <span>{onHoverValue ? displayValues[onHoverValue] : selectedValue > 0 ? displayValues[selectedValue] : ""}</span>}
        </div>
      </div>
      {meta.touched && meta.error ? <div className="d-block invalid-feedback">{meta.error}</div> : null}
    </>
  );
};

export default RateStarInput;
