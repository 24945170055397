import { Input } from "reactstrap";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const DateInput = ({ date, onDateChanged }) => {
  return (
    <>
      <DatePicker
        className="form-control"
        selected={date}
        todayButton="Aujourd'hui"
        locale="fr"
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={(date) => onDateChanged(date)}
        customInput={<Input />}
      />
    </>
  );
};

export default DateInput;
