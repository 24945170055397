import { Input, Label, FormFeedback } from "reactstrap";
import { useField } from "formik";
import { Autocomplete } from "../AutoComplete";

const TypeAheadInput = ({ label, getValues, placeHolder, onChange, ...props }) => {
  const [field, meta, helper] = useField(props);

  return (
    <>
      <Label className="fw-bold" htmlFor={props.id || props.name}>
        {label}
      </Label>
      <Autocomplete
        className="w-100"
        {...field}
        error={meta.touched && meta.error ? meta.error : null}
        inputPlaceholder={placeHolder}
        itemToString={(option) => option?.name || ""}
        getMatches={getValues}
        onChange={(value) => {
          helper.setTouched(true);
          helper.setValue(value);
          onChange(value);
        }}
      />
    </>
  );
};

export default TypeAheadInput;
