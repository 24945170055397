export const ByteUtils = {
  format: (bytes, decimals = 2) => {
    if (!+bytes) return "0 Octet";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Octets", "KO", "MO", "GO", "TO", "PO", "EO", "ZO", "YO"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },
};

export default ByteUtils;
